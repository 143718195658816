import React from "react";
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';


const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        margin: 0,
        position: 'relative',
    },
    success: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fail: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

type LoadingButtonProps = {
    text: string,
    loading: boolean,
    valid: boolean,
    success: boolean,
    fail: boolean,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void,
}


function LoadingButton({text, loading, valid, success, fail, onClick}: LoadingButtonProps) {
    const classes = useStyles();

    const calculateClass = () => {
        if (fail) { return classes.fail}
        if (!loading && success) { return classes.success }
    }

    return (
        <div className={classes.wrapper}>
            <Button variant="contained" color="primary" fullWidth disabled={loading || !valid} type="submit" onClick={onClick} className={calculateClass()}>
                {text}
            </Button>
            {loading && <CircularProgress size={24} className={classes.progress} />}
        </div>
    )
}

export default LoadingButton;
