import React, { useState } from 'react';
import { FaTasks, FaUsers, FaEye, FaBook, FaCog } from 'react-icons/fa'; // Icons for the tabs
import './TeacherToolTabs.css'; // Make sure to add your CSS here for styling

// Define the types for the tabs
type Tab = {
  name: string;
  icon: JSX.Element;
};

const tabs: Tab[] = [
  { name: 'Assign work', icon: <FaTasks /> },
  { name: 'Manage users', icon: <FaUsers /> },
  { name: 'Live view', icon: <FaEye /> },
  { name: 'Mark book', icon: <FaBook /> },
  { name: 'Settings', icon: <FaCog /> },
];

const TeacherToolTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Assign work');

  const renderContent = () => {
    switch (activeTab) {
      case 'Assign work':
        return <div>Assign work content</div>;
      case 'Manage users':
        return <div>Manage users content</div>;
      case 'Live view':
        return <div>Live view content</div>;
      case 'Mark book':
        return <div>Mark book content</div>;
      case 'Settings':
        return <div>Settings content</div>;
      default:
        return <div>Assign work content</div>;
    }
  };

  return (
    <div className="tab-container">
      <div className="tabs">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={`tab ${activeTab === tab.name ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.icon}
            <span className="tab-name">{tab.name}</span>
          </div>
        ))}
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default TeacherToolTabs;
