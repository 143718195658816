// src/ClassHomePage.tsx
import React, { useState } from 'react';
import { FaTasks, FaUsers, FaEye, FaBook, FaCog } from 'react-icons/fa';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import { ClassHomePageProps, ClassHomePageState } from './types';
import MarkBooksPage from '../MarkBooksPage/MarkBooksPage';
import { AddClassComponent, DeleteDialog, fetchPupilsWssStats, PopupClassInstructions, PopupEmptyClassInstructions } from '../../../../Common';
import { getexercisestree, topic_table } from '../../../../topics';
import { Cohort } from '../../../../my_interfaces';
import UserManagerList from '../UserManagerList/UserManagerList';

const tabs = [
    { name: 'Assign work', icon: <FaTasks /> },
    { name: 'Markbooks', icon: <FaBook /> },
    { name: 'Manage users', icon: <FaUsers /> },
    //{ name: 'Live', icon: <FaEye /> },
    { name: 'Settings', icon: <FaCog /> },
];

export const ClassHomePage: React.FC<ClassHomePageProps> = (props) => {
    const [activeTab, setActiveTab] = useState<string>('Assign work');
    const [state, setState] = useState<ClassHomePageState>({
        showingdeleteclassdialog: false,
        classisdeleting: Array(props.classes.length).fill(false),
        selectedclass: undefined,
        classesexpanded: Array(props.classes.length).fill(false),
        classdetailsdata: Array(props.classes.length),
        groupsexpanded: [],
        selectedjoinclass: null,
        showingemptyclasspopup: false,
        assignmentstoggled: {},
    });

    const deleteClass = async (classindex: number) => {
        if (props.classes !== undefined && props.classes.length > classindex) {
            const url = '/modifyRecord';
            const data = {
                action: "delete",
                ID: props.classIDtodisplay,
                record_type: "classe",
            };
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then(() => props.fetchClasses())
                .then(() => {
                    const newState = {
                        ...state,
                        classisdeleting: { ...state.classisdeleting, [classindex]: false },
                    };
                    setState(newState);
                });
        } else {
            throw new Error("this.state.classes is undefined or too short");
        }
    };

    const selectClassToDisplay = (classes: Cohort[]): Cohort[] => {
        console.log("selectClassToDisplay called with classIDtodisplay:", props.classIDtodisplay);
        console.log("Available classes:", classes);
    
        if (!classes || classes.length === 0) {
            console.warn("Classes array is empty or undefined");
            return [];
        }
    
        const classtodisplay = classes.find(clas => clas.ID === props.classIDtodisplay);
    
        if (!classtodisplay) {
            console.error("Couldn't extract class from list of classes using ID:", props.classIDtodisplay);
            // You could return a default value or handle this case differently.
            return []; // Return an empty array or a default class object as needed.
        }
    
        return [classtodisplay];
    };
    

    const showPopupForClass = (class_index: number) => {
        const selectedclassname = props.classes[class_index].name;
        fetch("/worksheetsassignedtoclass", {
            method: 'POST',
            body: JSON.stringify({ classname: selectedclassname }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(classassignments => {
                let firstAssignmentName = "Ask your teacher which exercise to begin with";
                if (classassignments.length > 0) {
                    firstAssignmentName = classassignments[0].name;
                } else {
                    showPopupForEmptyClass();
                    return;
                }
                setState({
                    ...state,
                    selectedjoinclass: class_index,
                    assignmentstoggled: { ...state.assignmentstoggled, [class_index]: firstAssignmentName },
                });
            })
            .catch(error => {
                console.error('Error fetching class assignments:', error);
                setState({
                    ...state,
                    assignmentstoggled: { ...state.assignmentstoggled, [class_index]: "Failed to fetch assignments, please try again." },
                });
            });
    };

    const showPopupForEmptyClass = () => {
        setState({ ...state, showingemptyclasspopup: true });
    };

    const setisLive = (classindex: number, class_ID: number, assignment_ID: number, islive: boolean) => {
        if (props.classes !== undefined && props.classes.length > classindex) {
            const url = '/modify_assignment';
            const data = {
                class_ID,
                assignment_ID,
                islive,
            };
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then(() => props.fetchClasses());
        } else {
            throw new Error("this.state.classes is undefined or too short");
        }
    };

    const deleteClassCaller = (class_index: number) => {
        setState({
            ...state,
            classisdeleting: { ...state.classisdeleting, [class_index]: true },
            selectedclass: undefined,
            showingdeleteclassdialog: false,
        });
        deleteClass(class_index);
    };

    const ondeleteClassClicked = (class_index: number) => {
        setState({
            ...state,
            showingdeleteclassdialog: true,
            selectedclass: class_index,
        });
    };

    const onDeleteDialogResponse = (deleteconfirmed: boolean) => {
        if (deleteconfirmed) {
            if (state.selectedclass === undefined) {
                throw new Error("selectedclass should not be undefined when deleting a class");
            } else {
                deleteClassCaller(state.selectedclass);
                props.history.push('/teacherhome');
            }
        } else {
            setState({
                ...state,
                selectedclass: undefined,
                showingdeleteclassdialog: false,
            });
        }
    };

    const recordgroupexpansion = (class_index: number, currentnode: TreeNode) => {
        const list = [...(state.groupsexpanded[class_index] || [])];
        const groupid = currentnode.ID as string;

        const groupindex = list.indexOf(groupid);
        if (groupindex === -1) {
            list.push(groupid);
        } else {
            list.splice(groupindex, 1);
        }

        setState({
            ...state,
            groupsexpanded: { ...state.groupsexpanded, [class_index]: list },
        });
    };

    const closePopup = () => {
        setState({
            ...state,
            selectedjoinclass: null,
            showingemptyclasspopup: false,
        });
    };

    const renderTabContent = () => {
        const c = props.classes.find((clas) => clas.ID === props.classIDtodisplay);
        if (!c) return <div>No class found with the provided ID.</div>;

        switch (activeTab) {
            case 'Assign work':
                return (
                    <div>
                        <h3>Assign Work</h3>
                        
                        <DropdownTreeSelect
                            data={getexercisestree(props.publicworksheets, topic_table, c.Assignments, state.groupsexpanded[0])}
                            mode={"hierarchical"}
                            texts={{ placeholder: "Select assignment" }}
                            onNodeToggle={(currentnode) => recordgroupexpansion(0, currentnode)}
                            onChange={(currentnode) => {
                                if (!currentnode.isws) throw new Error("trying to assign a group");
                                props.setAssignmentSelected(0, currentnode.value as unknown as number);
                            }}
                            inlineSearchInput={true}
                        />

                        <p>Exercises can be assigned using the dropdown above or from the exercises page. The exercises page allows you to view the questions. Once assigned, you can view assigned work in the markbooks tab.</p>

                    </div>
                );
            case 'Markbooks':
                return (
                    <div>
                        <h3>Markbooks</h3>
                        <MarkBooksPage
                            cohort={c}
                            publicworksheets={props.publicworksheets}
                            onAssignmentButtonClick={props.onAssignmentbuttonclicked}
                            setisLive={setisLive}
                            fetchPupilsWssStats={fetchPupilsWssStats}
                        />
                    </div>
                );
            case 'Manage users':
                return (
                    <div>
                        <h3>Manage Users</h3>
                        <p
                            hidden={!(c.Assignments.length === 0)}
                        >It is recommended to assign work before inviting students to join the class.</p>
                        <button
                            className="markbookbutton"
                            onClick={() => showPopupForClass(0)}
                        >
                            Show join class instructions
                        </button>
                        <br/>
                        <h3 style={{marginTop:"1em"}} >Students of the class</h3>
                        <UserManagerList
                            class_ID={c.ID}
                        />
                    </div>
                );
            case 'Live':
                return (
                    <div>
                        <h3>Live</h3>
                        <p>
                            Coming soon, for now, use the Markbooks tab. Markbooks update real-time.
                        </p>
                    </div>
                );
            case 'Settings':
                return (
                    <div>
                        <h3>Settings</h3>
                        <br/>
                        <h3>Change class name</h3>
                        <AddClassComponent 
								fetchClasses={props.fetchClasses}
								onlyeditting={true}
								cohort={c}
								classname={c.name}
							/>
                            <br/>
                            <h3>Delete Class</h3>
                        <button
                            className="markbookbutton"
                            onClick={(e) => { e.preventDefault(); ondeleteClassClicked(0); }}
                        >
                            Delete Class ❌
                        </button>
                    </div>
                );
            default:
                return null;
        }
        
        
    };

    const c = selectClassToDisplay(props.classes);
    const popup = state.selectedjoinclass !== null ? (
        <PopupClassInstructions
            selectedClassCode={c[state.selectedjoinclass].class_code}
            firstAssignmentToggled={state.assignmentstoggled[state.selectedjoinclass]}
            closePopup={closePopup}
        />
    ) : null;
    const popup2 = state.showingemptyclasspopup ? (
        <PopupEmptyClassInstructions closePopup={closePopup} />
    ) : null;

    return (
        <div className="tab-container">
            <div className="tabs">
                {tabs.map((tab) => (
                    <div
                        key={tab.name}
                        className={`tab ${activeTab === tab.name ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.name)}
                    >
                        {tab.icon}
                        <span className="tab-name">{tab.name}</span>
                    </div>
                ))}
            </div>
    
            <div className="tab-content">
                {renderTabContent()}
            </div>
    
            {popup}
            {popup2}
            <div hidden={!state.showingdeleteclassdialog}>
                <DeleteDialog
                    onDeleteDialogResponse={onDeleteDialogResponse}
                    record_type="class"
                />
            </div>
        </div>
    );
};

export default ClassHomePage;
