// src/Markbooks.tsx
import React, { useState } from 'react';
import AssignmentsList from './AssignmentList';
import { ClassOverview } from '../../../../Common';
import { Cohort, Worksheet, Assignment, PupilWssStats } from '../../../../my_interfaces';

interface MarkbooksProps {
    cohort: Cohort;
    publicworksheets: Worksheet[];
    onAssignmentButtonClick: (Class_ID: number, Assignment_ID: number) => void;
    setisLive: (classindex: number, class_ID: number, assignment_ID: number, islive: boolean) => void;
    fetchPupilsWssStats: (params: { C_ID: number }) => Promise<PupilWssStats[]>;
}

const Markbooks: React.FC<MarkbooksProps> = ({
    cohort,
    publicworksheets,
    onAssignmentButtonClick,
    setisLive,
    fetchPupilsWssStats,
}) => {
    const [isClassDetailExpanded, setIsClassDetailExpanded] = useState<boolean>(false);
    const [classDetailsData, setClassDetailsData] = useState<PupilWssStats[]|undefined>(undefined);

    const toggleClassDetail = async () => {
        const expandingdetails = !isClassDetailExpanded;
        setIsClassDetailExpanded(expandingdetails);

        if (expandingdetails) {
            const classdetails = await fetchPupilsWssStats({ C_ID: cohort.ID });
            setClassDetailsData(classdetails);
        }
    };

    return (
        <>
            <button
                className="markbookbutton summary"
                hidden={cohort.Assignments.length < 2}
                onClick={toggleClassDetail}
            >
                Show markbook summary ⇲
            </button>
            <div hidden={!isClassDetailExpanded}>
                <ClassOverview
                    class={cohort}
                    onAssignmentbuttonclicked={onAssignmentButtonClick}
                    visible={isClassDetailExpanded}
                    classdetails={classDetailsData}
                    classindex={0}
                    toggleClassDetail={toggleClassDetail}
                />
            </div>
            <div style={{marginTop:"20px"}}>
                <AssignmentsList
                    assignments={cohort.Assignments}
                    publicworksheets={publicworksheets}
                    classID={cohort.ID}
                    onAssignmentButtonClick={onAssignmentButtonClick}
                    onToggleLive={(classID, assignmentID, islive) =>
                        setisLive(0, classID, assignmentID, islive)
                    }
                />
            </div>
        </>
    );
};

export default Markbooks;
