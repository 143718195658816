//import { ErrorSharp, FormatAlignCenterSharp, Waves } from "@mui/icons-material"
import { group } from "console";
import { skipPartiallyEmittedExpressions, SymbolDisplayPartKind } from "typescript"
import { Assignment, field_type_desc_names, Worksheet } from "./my_interfaces"

//export const exercisestree = [
//    {label: 'search me',
//        value: 'searchme',
//        children: [
//        {
//            label: 'search me too',
//            value: 'searchmetoo',
//            children: [
//            {
//                label: 'No one can get me',
//                value: 'anonymous',
//            },
//            ],
//        },
//        ],
//    },
//    {label: 'search me',
//    value: 'searchme',
//    children: [
//        {
//        label: 'search me too',
//        value: 'searchmetoo',
//        children: [
//            {
//            label: 'No one can get me',
//            value: 'anonymous',
//            },
//        ],
//        },
//    ],
//    }
//]






export interface Topic {
	topic_name : string;
	T_ID : number;
    status?:"empty",//empty means there is nothing useful in there
	children : {type:"ws"|"grp"; ID:number}[];
}
export var topic_table:Topic[] = [
    {
        topic_name:"Topics",
        T_ID:0,
        children: [
            {ID:3,type:"grp"},//KS5 physics
            {ID:2,type:"grp"},//KS4 physics
            {ID:1,type:"grp"},//KS3 physics
        //    {ID:92,type:"grp"},//KS4 chemistry
        //    {ID:89,type:"grp"}, //HK Hong Kong
        ],
    },
{
    topic_name:"KS3 Science",
    T_ID:1,
    children: [
        {ID:0,type:"ws"}, //Variables
        {ID:4,type:"grp"}, //KS3 force and motion
        {ID:5,type:"grp"},
        {ID:6,type:"grp"},
    ],
},
{
    topic_name:"KS4 Physics",
    T_ID:2,
    children: [
        {ID:7,type:"grp"},
        {ID:8,type:"grp"},
        {ID:9,type:"grp"},
        {ID:10,type:"grp"},
        {ID:11,type:"grp"},
        {ID:12,type:"grp"},
        {ID:13,type:"grp"},
        {ID:14,type:"grp"},
        {ID:15,type:"grp"},
        {ID:90,type:"grp"},//practical skills
    ],
},
{
    topic_name:"KS5 Physics",
    T_ID:3,
    children: [
    //    {ID:29,type:"grp"},
        {ID:16,type:"grp"},
        {ID:17,type:"grp"},
        {ID:18,type:"grp"},
        {ID:34,type:"grp"},
        {ID:19,type:"grp"},
        {ID:27,type:"grp"},
        {ID:20,type:"grp"},
        {ID:21,type:"grp"},
        {ID:23,type:"grp"},
        {ID:24,type:"grp"},
        {ID:25,type:"grp"},
        {ID:26,type:"grp"}, //force fields
        {ID:28,type:"grp"}, //turning points
    ],
},
{
    topic_name:"KS3 Force and motion",
    T_ID:4,
    children: [
        //{ID:12,type:"ws"}, //Forces 1 - legacy
        {ID:175,type:"ws"}, //Forces on dropped object 1: gravitational force
        {ID:176,type:"ws"}, //Forces on dropped object 2: air resistance
        {ID:177,type:"ws"}, //Forces on dropped object 3: skydiver video
        //{ID:13,type:"ws"}, //Forces 2 - Magnets - legacy
        {ID:178,type:"ws"}, //Magnetic force intro KS3-KS4
        {ID:180,type:"ws"}, //Normal contact force - intro
        {ID:41,type:"ws"}, //Identifying type of force
        {ID:179,type:"ws"}, //Force arrows in diagrams - magnets
        {ID:181,type:"ws"}, //Force arrows - Normal contact force
    //    {ID:14,type:"ws"}, //Forces 3 - Normal contact force - legacy
    //    {ID:17,type:"ws"}, //Forces 4 - Unbalanced forces
    //    {ID:18,type:"ws"}, //Forces 5 - Electrostatics
        {ID:42,type:"ws"}, //Adding forces in 1D
        {ID:43,type:"ws"}, //Effect of resultant force
    ],
},
{
    topic_name:"KS3 SLG Solids, liquids, gases",
    T_ID:5,
    children: [
        {ID:5,type:"ws"},
        {ID:46,type:"ws"},
        {ID:47,type:"ws"},
        {ID:48,type:"ws"},
        {ID:49,type:"ws"},
    ],
},
{
    topic_name:"KS3 Electricity",
    T_ID:6,
    children: [        
	{ID:229,type:"ws"},//Role of components in a circuit
    	{ID:227,type:"ws"},//Circuit symbols - level 1
    	{ID:301,type:"ws"},//Completing the circuit and switches - level 1
    	{ID:302,type:"ws"},//Completing the circuit and switches - level 2
        {ID:231,type:"ws"},//Series and parallel components in terms of junctions
        {ID:254,type:"ws"},//Series and parallel circuits - level 1
        //{ID:232,type:"ws"},//Current rules - series and parallel - basic
        
	{ID:251,type:"ws"},//Current rules - level 1
	{ID:252,type:"ws"},//Current rules - level 2
	//{ID:233,type:"ws"},//Voltage rules - series and parallel - basic
        //{ID:100,type:"ws"},//basic current rules legacy
    ],
},
{
    topic_name:"KS4 Numerical Skills",
    T_ID:7,
    children: [
        {ID:15,type:"ws"},
        {ID:16,type:"ws"},
        {ID:20,type:"ws"},
    ],
},
{
    topic_name:"KS4 Electricity",
    T_ID:8,
    children: [
        {ID:6,type:"grp"},//KS3 electricity
        {ID:298,type:"ws"},// Key terms - voltage and current - level 1
        {ID:297,type:"ws"},// Key terms - voltage and current - level 2-3
        //{ID:101,type:"ws"},// some incomplete legacy q = I t exercise, not visible
        {ID:237,type:"ws"},// Calculations using current = charge / time
	{ID:236,type:"ws"},//Connecting voltmeters and ammeters
        {ID:300,type:"ws"},// How resistance and potential difference affect current - level 1
        {ID:238,type:"ws"},//Relationships between variables
        {ID:299,type:"ws"},//Ohm’s law - level 2
        {ID:240,type:"ws"},//Ohm's law intermediate
    	//{ID:231,type:"ws"},//Key terms - series and parallel, replaced with junctions ...
        //{ID:107,type:"ws"},//Current rules - intermediate
	{ID:253,type:"ws"},//Voltage rules - level 1
        {ID:234,type:"ws"},//Voltage rules - part 2
        {ID:235,type:"ws"},//Voltage rules - part 3
        //{ID:107,type:"ws"},//Voltage rules - part 3
        {ID:241,type:"ws"},//Combining resistors - basic
        {ID:242,type:"ws"},//Combining resistors - intermediate
        {ID:243,type:"ws"},//Combining resistors - advanced
        {ID:303,type:"ws"},//Effect of adding lamps in parallel or series - level 1
	{ID:107,type:"ws"},//Spotting components in series and parallel level 2-3
        {ID:244,type:"ws"},//Calculations using V = I R
        {ID:245,type:"ws"},//Two-step calculations using V = I R
        {ID:109,type:"ws"},//V=IR calculations in a circuit (old exercise)
        {ID:246,type:"ws"},//Ohm' law application

        {ID:90,type:"grp"},//KS4 Practical skills
	    
        {ID:247,type:"ws"},//Key terms - practicals
        {ID:248,type:"ws"},//Core practical - resistance of a wire
        {ID:249,type:"ws"},//Graph skills - choosing axes
        {ID:250,type:"ws"},//Graph skills - best fit lines

	    
    	{ID:230,type:"ws"},//Circuit symbols - level 2
        {ID:290,type:"ws"},//Basic component properties - level 1
        {ID:291,type:"ws"},//Basic component properties - level 2
        {ID:293,type:"ws"},//Basic component properties - level 3
        {ID:267,type:"ws"},//Thermistor, LDR and diode - level 1, formally Resistance of different components
        {ID:292,type:"ws"},//Thermistor, LDR and diode - level 2
        {ID:268,type:"ws"},//Component graphs
	    
        {ID:102,type:"ws"},//102 ✎ Charge carriers qualitative (not AQA)
        {ID:112,type:"ws"},//112 ✎ Current and temperature - level 3-4 (not AQA)
	    
        {ID:91,type:"grp"},//Electrical energy and power calculations
	    
        {ID:270,type:"ws"},//Mains in the UK - alternating pd 
        {ID:271,type:"ws"},//Mains in the UK - live wire & safety
        {ID:272,type:"ws"},//National grid - level 1
        {ID:294,type:"ws"},//National grid - level 2

        {ID:80,type:"ws"},//Electricity intro quiz (not public)
    ],
},
{
    topic_name:"KS4 Electrostatics",
    T_ID:9,
    children: [
        {ID:18,type:"ws"},
        {ID:9,type:"ws"},
    ],
},
{
    topic_name:"KS4 SLG Particle model",
    T_ID:10,
    children: [
        {ID:5,type:"grp"},
        {ID:5,type:"ws"},
        {ID:50,type:"ws"},
        {ID:51,type:"ws"}, //Heating - hot to cold
        {ID:52,type:"ws"},
        {ID:53,type:"ws"},
        {ID:54,type:"ws"},
    ],
},
{
    topic_name:"KS4 Atom, radioactivity and nuclear",
    T_ID:11,
    children: [
        {ID:7,type:"ws"},//hidden
        {ID:8,type:"ws"},//hidden
        //{ID:34,type:"ws"},//Radioactivity 1: atomic structure and isotopes KS4,5
        //{ID:35,type:"ws"},//Radioactivity 2: what is nuclear radiation KS4,5
        {ID:256,type:"ws"},//Structure of atom
        {ID:257,type:"ws"},//A-Z notation and isotopes
        {ID:258,type:"ws"},//what is nuclear radiation
        {ID:261,type:"ws"},//radiation ionisation and penetration
        {ID:262,type:"ws"},//decay equations
        {ID:36,type:"ws"},//Radioactivity 3: Half life KS4,5
        {ID:37,type:"ws"},//Radioactivity 4: Practical work KS4,5
        {ID:38,type:"ws"},//Radioactivity 5:uses KS4,5
        {ID:55,type:"ws"},//Atomic Structure Extra KS4,5
    ],
},
{
    topic_name:"KS4 Forces and motion",
    T_ID:12,
    children: [
        {ID:4,type:"grp"}, //KS3 force and motion
	{ID:211,type:"ws"}, //Balanced and unbalanced forces BEST resource
        {ID:171,type:"ws"}, //Scalars and Vectors
        {ID:170,type:"ws"}, //Distance vs displacement
        {ID:174,type:"ws"}, //Vector addition by drawing intro
        {ID:173,type:"ws"}, //Force facts
        {ID:45,type:"ws"}, //Mass vs Weight
        {ID:21,type:"grp"},
    ],
},
//{
//    topic_name:"Forces and motion",
//    T_ID:13,mistake here duplicate
//    children: [
//    ],
//},
{
    topic_name:"KS4 Energy",
    T_ID:14,
    children: [
        {ID:210,type:"ws"},//Energy stores and transfers BEST
        
	{ID:186,type:"ws"},//EQ Identify energy stores GCSE part 1
        {ID:193,type:"ws"},//EQ Identify energy stores GCSE part 2
        {ID:195,type:"ws"},//EQ describe energy transfers
	    
        {ID:221,type:"ws"},//Energy best 2
        {ID:216,type:"ws"},//MC efficiency and wasted energy
        {ID:200,type:"ws"},//EQ Wasted energy in a system
        //{ID:30,type:"ws"},//calc work
        //{ID:30,type:"ws"},//calc KE, EPE, GPE
        //{ID:30,type:"ws"}, //Power
        
        {ID:212,type:"ws"},//Power calcs - symbols and units
        {ID:215,type:"ws"},//Power calcs - simple power calcs
        {ID:213,type:"ws"},//Power calcs - identify the equation
        {ID:220,type:"ws"},//EQ Power multistep calculations

	//{ID:,type:"ws"},//EQ Specific heat calculations
        {ID:192,type:"ws"},//EQ Specific heat practical
        {ID:219,type:"ws"},//EQ thermal conductivity incl. practical (triple only)
	    
        {ID:202,type:"ws"},//MC Energy resources, renewable and non-renewable
        {ID:201,type:"ws"},//EQ Energy resources, renewable and non-renewable
        {ID:214,type:"ws"},//EQ Energy resources, advantages and disadvantages
        {ID:222,type:"ws"},//MC Power stations
        {ID:194,type:"ws"},//EQ Power stations
        {ID:203,type:"ws"},//EQ Energy topic wordy summary
    ],
},
{
    topic_name:"KS4 Waves",
    T_ID:15,
    children: [
        {ID:19,type:"ws"},//Wave properties
        {ID:21,type:"ws"},//Waves booklet 1: Transverse and longitudinal waves
        {ID:23,type:"ws"},//Waves booklet 2: Time period and frequency
        {ID:24,type:"ws"},//Waves booklet 3: The wave equation
        {ID:25,type:"ws"},//Waves booklet 4: Ripple tank
        {ID:28,type:"ws"},//Waves booklet 7: Electromagnetic waves
        {ID:29,type:"ws"},//Waves booklet 8: Uses EM waves
        {ID:32,type:"ws"},//Wave booklet 9: IR
    ],
},
{
    topic_name:"KS5 Definitions",
    T_ID:16,
    children: [
        //{ID:31,type:"ws"},//A-level practical key words
        {ID:208,type:"ws"},//A-level particle interactions definitions
        {ID:209,type:"ws"},//A-level photon interactions definitions
        {ID:31,type:"ws"},//A-level waves definitions
        {ID:73,type:"ws"},//A-level definitions - Mechanics
        {ID:72,type:"ws"},//A-level definitions - Materials
        {ID:204,type:"ws"},//A-level electricity definitions
        {ID:196,type:"ws"},//A-level circular motion definitions
        {ID:226,type:"ws"},//A-level SHM definitions
        {ID:199,type:"ws"},//A-level thermal physics definitions
        {ID:197,type:"ws"},//A-level gravitational field definitions
        {ID:198,type:"ws"},//A-level electric field definitions
        {ID:225,type:"ws"},//A-level capacitor definitions
        {ID:205,type:"ws"},//A-level magnetic field definitions
        {ID:207,type:"ws"},//A-level radioactivity definitions
        {ID:266,type:"ws"},//A-level nuclear energy definitions
        {ID:206,type:"ws"},//A-level turning points definitions
    ],
},
{
    topic_name:"KS5 Measurements and their errors",
    T_ID:17,
    children: [
        {ID:79,type:"ws"},
        {ID:99,type:"ws"},
        {ID:77,type:"ws"},
        {ID:97,type:"ws"},
        {ID:153,type:"ws"},//Measurements
        {ID:154,type:"ws"},//Practical data handling
        {ID:156,type:"ws"},//Graph work
    ],
},
{
    topic_name:"KS5 Particle zoo and fundamental forces",
    T_ID:18,
    children: [
        {ID:34,type:"ws"},
        {ID:35,type:"ws"},
        {ID:55,type:"ws"},
        {ID:56,type:"ws"},
        {ID:57,type:"ws"},
        {ID:58,type:"ws"},
        {ID:59,type:"ws"},
        {ID:60,type:"ws"},
        {ID:62,type:"ws"},
        {ID:63,type:"ws"},
        {ID:64,type:"ws"},
        {ID:65,type:"ws"},
        {ID:66,type:"ws"},
        {ID:67,type:"ws"},
        {ID:68,type:"ws"},
        {ID:148,type:"ws"},//Quantum numbers and interactions KS5
    ],
},
{
    topic_name:"KS5 Nuclear 2",
    T_ID:19,
    children: [
        {ID:11,type:"grp"},
        {ID:18,type:"grp"},
        {ID:92,type:"ws"},//Rutherford scattering
        {ID:93,type:"ws"},//Size of the nucleus
        {ID:94,type:"ws"},//Nuclear density
        {ID:95,type:"ws"},//Investigating atomic structure by diffraction
        {ID:96,type:"ws"},//α, β and γ radiation
        {ID:97,type:"ws"},//Nuclear and Radioactivity: Experimental and safety
        {ID:98,type:"ws"},//Inverse square law
    ],
},
{
    topic_name:"KS5 Nuclear Energy",
    T_ID:27,
    children: [
        {ID:114,type:"ws"},
        {ID:115,type:"ws"},
        {ID:116,type:"ws"},
        {ID:122,type:"ws"},
        {ID:117,type:"ws"},
        {ID:118,type:"ws"},
    ],
},
{
    topic_name:"KS5 Waves",
    T_ID:20,
    children: [
        {ID:15,type:"grp"},
        {ID:31,type:"ws"},
        {ID:144,type:"ws"},//Polarised waves
        {ID:145,type:"ws"},//Reflection
        {ID:146,type:"ws"},//Refraction
        {ID:147,type:"ws"},//Total internal reflection
        {ID:149,type:"ws"},//Diffraction
        {ID:133,type:"ws"},//Young's double slit
        {ID:155,type:"ws"},//Diffraction equations
    ],
},
{
    topic_name:"KS5 Mechanics 1",
    T_ID:21,
    children: [
        {ID:61,type:"ws"},//vectors and scalars
        {ID:78,type:"ws"},//Dynamics = Terminal velocity
        {ID:81,type:"ws"},//Momentum - non-continuous
        {ID:87,type:"ws"},//Momentum continuous flow
        {ID:88,type:"ws"},//Energy: W = F s cosθ
        {ID:89,type:"ws"},//Energy: power
        {ID:90,type:"ws"},//Energy: efficiency
        {ID:91,type:"ws"},//Energy: law of conservation
        {ID:73,type:"ws"},//A-level definitions - Mechanics
    ],
},
//{
//    topic_name:"KS5 Mechanics 2",
//    T_ID:22,
//    children: [
//        
//    ],
//},
{
    topic_name:"KS5 Materials",
    T_ID:23,
    children: [
        {ID:74,type:"ws"},
        {ID:75,type:"ws"},
        {ID:76,type:"ws"},
        {ID:99,type:"ws"},
        {ID:77,type:"ws"},
        {ID:72,type:"ws"},
    ],
},
{
    topic_name:"KS5 Electricity",
    T_ID:24,
    children: [
        {ID:8,type:"grp"},//KS4 Electricity
            //102 ✎ Nature of electrical conductors
            //104 ✎ Basic Voltage Rules
            //105 ✎ Electricity key terms
            //106 ✎ V = IR qualitative
            //107 ✎ Spotting components in series and parallel
	    
	{ID:105,type:"ws"},// 105 ✎ Electricity key terms Hidden
        {ID:106,type:"ws"},// 106 ✎ V = IR qualitative Hidden
	    
	{ID:108,type:"ws"},//108 ✎ Combining resistors in series and parallel
            //112 ✎ Current and temperature
	{ID:110,type:"ws"},//Circuit component symbols worksheet
        {ID:103,type:"ws"},//Current and charged particles quantitativ
	{ID:104,type:"ws"},//104 ✎ Basic Voltage Rules
        {ID:113,type:"ws"},//Potential divider
        {ID:124,type:"ws"},//Superconductors
        {ID:125,type:"ws"},//Current-voltage characteristics
        {ID:123,type:"ws"},//Emf and internal resistance
        {ID:126,type:"ws"},//Potentiometer
        {ID:151,type:"ws"},//Alternating current
        {ID:224,type:"ws"},//Diagnostic assessment - Electricity MCQ from Ragavan mixed
    ],
},
{
    topic_name:"KS5 SLG Thermal",
    T_ID:25,
    children: [
        {ID:10,type:"grp"},//KS4 SLG Particle model
        {ID:150,type:"ws"},//Heating cooling curve
	{ID:188,type:"ws"},//Internal Energy A level
	//{ID:69,type:"ws"},//Internal Energy Alevel legacy
	{ID:189,type:"ws"},//First law of thermodynamics
        {ID:84,type:"ws"},//Absolute zero temperature
        {ID:82,type:"ws"},//Specific heat capacity
        {ID:83,type:"ws"},//Specific latent heat
        {ID:85,type:"ws"},//Molecular kinetic theory model
        {ID:86,type:"ws"},//p V / T and kinetic theory
    ],
},
{
    topic_name:"KS5 Force Fields",
    T_ID:26,
    children: [
        {ID:71,type:"ws"},//Newton's law of gravitation
        {ID:135,type:"ws"},//Magnetism basics
        {ID:136,type:"ws"},//Magnetic fields- wordy
        {ID:137,type:"ws"},//Electromagnets
        {ID:138,type:"ws"},//Motor effect and fleming’s left hand rule
        {ID:139,type:"ws"},//DC motors
        {ID:140,type:"ws"},//Magnetic force on a current carrying wire
        {ID:141,type:"ws"},//Electro-magnetism and a top-pan balance
        {ID:142,type:"ws"},//Force on charged particle
        {ID:143,type:"ws"},//Circular motion and the cyclotron
    ],
},
{
    topic_name:"KS5 Turning Points (AQA Option)",
    T_ID:28,
    children: [
        {ID:119,type:"ws"},
        {ID:120,type:"ws"},
        {ID:121,type:"ws"},
        {ID:130,type:"ws"},//Find e specific charge with electric field
        {ID:129,type:"ws"},//How to find drag force (Stoke's law)?
        {ID:127,type:"ws"},//Finding the mass in Millikan's oil drop: part 1 (mass)
        {ID:128,type:"ws"},//Finding the charge in Millikan's oil drop: part 2 (charge)
        {ID:152,type:"ws"},//Velocity Selector
        {ID:131,type:"ws"},//Newton’s Corpuscular theory of light
        {ID:132,type:"ws"},//Huygen's principle
        {ID:133,type:"ws"},//Young's double slit
        {ID:134,type:"ws"},//How do you use E = mc²
    ],
},
//{
//    topic_name:"KS5 Physics AQA Advanced materials",
//    T_ID:29,
//    children: [
//        {ID:30,type:"grp"},//Paper 1
//        {ID:31,type:"grp"},//Paper 2
//        {ID:32,type:"grp"},//Paper 3A
//        {ID:33,type:"grp"},//Paper 3B: Turning Points
//    ],
//},
{
    topic_name:"Paper 1",
    T_ID:30,
    children: [
        {ID:18,type:"grp"},//Particles //KS5 Particle zoo and fundamental forces
        {ID:21,type:"grp"},//Force, energy and momentum
        {ID:24,type:"grp"},//Current electricity
        {ID:37,type:"grp"},//Periodic motion
        {ID:34,type:"grp"},//Electromagnetic radiation and quantum phenomena
    ],
},
{
    topic_name:"Paper 2",
    T_ID:31,
    children: [
        {ID:25,type:"grp"},//Thermal physics
        {ID:19,type:"grp"},//Radioactivity KS5 Nuclear 2
        {ID:27,type:"grp"},//Radioactivity Nuclear energy
        {ID:35,type:"grp"},//Gravitational fields
        {ID:36,type:"grp"},//Magnetic fields
    ],
},
{
    topic_name:"Paper 3 part A",
    T_ID:32,
    children: [
        {ID:17,type:"grp"},//KS5 Measurements and their errors
        {ID:25,type:"grp"},//Thermal physics (including required practical 8)
        {ID:24,type:"grp"},//Current electricity (including required practical 5)
        {ID:23,type:"grp"},//Materials (including required practical 4)
        {ID:79,type:"ws"},//Materials practical terminology
        {ID:99,type:"ws"},//Materials- Youn Modulus practical
    ],
},
{
    topic_name:"Paper 3 part B: turning points",
    T_ID:33,
    children: [       
        {ID:129,type:"ws"},//Millikan's oil drop experiment
        {ID:127,type:"ws"},
        {ID:128,type:"ws"},
        {ID:131,type:"ws"},//Significance of Young's double slits experiment
        {ID:132,type:"ws"},
        {ID:133,type:"ws"},
        {ID:134,type:"ws"},//Mass and energy
    ],
},
{
    topic_name:"KS5 Electromagnetic radiation and quantum phenomena (Photons and electrons)",
    T_ID:34,
    children: [
        {ID:38,type:"grp"},//Photoelectric effect
        {ID:39,type:"grp"},//Energy levels
        {ID:40,type:"grp"},//Wave particle duality
    ],
},

{
    topic_name:"KS5 Magnetism and electromagnetism - coming soon",
    T_ID:35,
    children: [],
},
{
    topic_name:"KS5 Gravitational fields - coming soon",
    T_ID:36,
    children: [],
},
{
    topic_name:"Periodic motion - coming soon",
    T_ID:37,
    children: [],
},
{
    topic_name:"Photoelectric effect",
    T_ID:38,
    children: [
        {ID:157,type:"ws"},//Introduction to the photoelectric effect
        {ID:158,type:"ws"},//Photoelectric effect explanations
        {ID:159,type:"ws"},//Photoelectric effect, wave theory comparison
        {ID:160,type:"ws"},//Applying voltage
        {ID:161,type:"ws"},//Gold leaf electroscopes and practical
    ],
},
{
    topic_name:"Energy levels",
    T_ID:39,
    children: [
        {ID:162,type:"ws"},//Energy levels and Excitation
        {ID:163,type:"ws"},//Photon emission and fluorescent tubes
    ],
},
{
    topic_name:"Wave particle duality - coming soon",
    T_ID:40,
    children: [],
},
//{
//    topic_name:"KS5 Electric fields - coming September",
//    T_ID:36,
//    children: [],
//},

//]
//
//export var topic_table_hk:Topic[] = [
    
{
    topic_name:"HKDSE - Physics",
    T_ID:89,
    children: [
        {ID:41,type:"grp"}, //I Heat and Gases
        {ID:42,type:"grp"}, //II Force and Motion
        {ID:43,type:"grp"}, //III Wave Motion
        {ID:44,type:"grp"}, //IV Electricity and Magnetism
        {ID:45,type:"grp"}, //V Radioactivity and Nuclear energy
    //    {ID:46,type:"grp"}, //VI Astronomy and Space Science
    //    {ID:47,type:"grp"}, //VII Atomic World
    //    {ID:48,type:"grp"}, //VIII Energy and Use of Energy
    //    {ID:49,type:"grp"}, //IX Medical Physics
    //    {ID:50,type:"grp"}, //X Investigative Study in Physics
    ],
},
{   
    topic_name:"I Heat and Gases",
    T_ID:41,
    children: [
        {ID:51,type:"grp"},//Temperature, heat and internal energy
        {ID:52,type:"grp"},//Transfer processes
        {ID:53,type:"grp"},//Change of state
        {ID:54,type:"grp"},//Gases
    ]
},
{   
    topic_name:"II Force and Motion",
    T_ID:42,
    children: [
        {ID:55,type:"grp"},//Position and movement
        {ID:56,type:"grp"},//Force and motion
        {ID:57,type:"grp"},//Projectile motion
        {ID:58,type:"grp"},//Work, energy and power
        {ID:59,type:"grp"},//Momentum
        {ID:60,type:"grp"},//Uniform circular motiom
        {ID:61,type:"grp"},//Gravitation
    ]
},
{   
    topic_name:"III Wave Motion",
    T_ID:43,
    children: [
         {ID:62,type:"grp"},//Nature and properties of waves
         {ID:63,type:"grp"},//Light
         {ID:64,type:"grp"},//Sound
    ]
},
{   
    topic_name:"IV Electricity and Magnetism",
    T_ID:44,
    children: [
        {ID:65,type:"grp"},//Electrostatics
        {ID:66,type:"grp"},//Circuits and domestic electricity
        {ID:67,type:"grp"},//Electromagnetism
    ]
},
{   
    topic_name:"V Radioactivity and Nuclear energy",
    T_ID:45,
    children: [
        {ID:68,type:"grp"},//Radiation and radioactivity
        {ID:69,type:"grp"},//Atomic model
        {ID:70,type:"grp"},//Nuclear energy 
    ]
},
{   
    topic_name:"VI Astronomy and Space Science",
    T_ID:46,
    status:"empty",
    children: [
        {ID:71,type:"grp"},//The universe as seen in different scales
        {ID:72,type:"grp"},//Astronomy through history
        {ID:73,type:"grp"},//Orbital motions under gravity
        {ID:74,type:"grp"},//Stars and the universe 
    ]
},
{   
    topic_name:"VII Atomic World",
    T_ID:47,
    status:"empty",
    children: [
        {ID:75,type:"grp"},//Rutherford’s atomic model
        {ID:76,type:"grp"},//Photoelectric effect
        {ID:77,type:"grp"},//Bohr’s atomic model of hydrogen
        {ID:78,type:"grp"},//Particles or waves
        {ID:79,type:"grp"},//Probing into nano scale
    ]
},
{   
    topic_name:"VIII Energy and Use of Energy",
    T_ID:48,
    status:"empty",
    children: [
        {ID:80,type:"grp"},//Electricity at home
        {ID:81,type:"grp"},//Energy efficiency in building and transportation
        {ID:82,type:"grp"},//Renewable and non-renewable energy sources
    ]
},
{   
    topic_name:"IX Medical Physics",
    T_ID:49,
    status:"empty",
    children: [
        {ID:83,type:"grp"},//Making sense of the eye and the ear
        {ID:84,type:"grp"},//Medical imaging using non-ionizing radiation
        {ID:85,type:"grp"},//Medical imaging using ionizing radiation
    ]
},
{   
    topic_name:"X Investigative Study in Physics",
    T_ID:50,
    status:"empty",
    children: [
        {ID:0,type:"ws"}, //Variables
        
    ]
},
{
    topic_name:"Temperature, heat and internal energy",
    T_ID:51,
    children: [
        {ID:86,type:"grp"},//temperature and thermometers
                //TODO This can be one simple worksheet that I make. 
            //realise temperature as the degree of hotness of an object
            //interpret temperature as a quantity associated with the average kinetic
                //energy due to the random motion of molecules in a system
            //explain the use of temperature-dependent properties in measuring temperature
            //define and use degree Celsius as a unit of temperature 
        
        {ID:87,type:"grp"},//heat and internal energy
            //realise that heat is the energy transferred as a result of the temperature difference between two objects
            //describe the effect of mass, temperature and state of matter on the internal energy of a system
            //relate internal energy to the sum of the kinetic energy of random motion and the potential energy of molecules in the system

        {ID:88,type:"grp"},//heat capacity and specific heat capacity
    ]
},
{
    topic_name:"Transfer processes",
    T_ID:52,
    children: [
        //conduction, convection and radiation
        {ID:52,type:"ws"},//Heating - types of heating
        {ID:54,type:"ws"},//Heating - conduction
        {ID:32,type:"ws"},//Wave booklet 9: IR TODO improve this
        //{ID:53,type:"ws"},//Heating - thermal expansion and convection //unclear if they need this
    ]
},
{
    topic_name:"Change of state",
    T_ID:53,
    children: [
        //melting and freezing, boiling and condensing
        {ID:46,type:"ws"},//	SLG - three states of matter
        {ID:48,type:"ws"},//	SLG - changing state

        //latent heat
        {ID:83,type:"ws"},//	Specific latent heat

        //evaporation
        {ID:47,type:"ws"},//	SLG - particle model
        {ID:49,type:"ws"},//	SLG - changing state - particle model
        //TODO definitely need an evaporation thing

    ]
},
{
    topic_name:"Gases",
    T_ID:54,
    children: [
        //general gas law - defintely a group
        //TODO
        //kinetic theory - definitely a group
        {ID:85,type:"ws"},//	Molecular kinetic theory model
        {ID:86,type:"ws"},//	Relationships between p, V and T in terms of a simple molecular model.

    ]
},
{
    topic_name:"Position and movement",
    T_ID:55,
    children: [
        //position, distance and displacement
            //TODO my worksheet
            //displacement time graphs
        //scalars and vectors
            //TODO build something for this
            {ID:61,type:"ws"},//vectors and scalars
        //speed and velocity
            //definitions + application
            //average and instantaneous
            //velocity time graphs
        //uniform motion
            //don't really understand this
        //acceleration
            //from maths
            //from graphs
            //acceleration time graphs
            //identify correct acceleration time graph
        //equations of uniformly accelerated motion (suvat)
        //vertical motion under gravity (projectile motion)
            {ID:78,type:"ws"},//Dynamics = Terminal velocity

    ]
},
{
    topic_name:"Force and motion",
    T_ID:56,
    children: [
        //Probably make a Newton's laws group
        //probably start with forces - identifying, then do the addition of them and free body diagrams
        //Newton's First Law of motion
            //Can make a worksheet on this
        //addition and resolution of forces
            //multiple worksheets on this
        //Newton's Second Law of motion
        //Newton's Third Law of motion 
            //TODO Make a simple w/s on this
        //mass and weight
            {ID:45,type:"ws"},//	Mass vs Weight
        //moment of a force
            //TODO make a moment worksheet
    ]
},
{
    topic_name:"Projectile motion",
    T_ID:57,
    status:"empty",
    children: [
        //short w/s and then direct to Isaac Physics
    ]
},
{
    topic_name:"Work, energy and power",
    T_ID:58,
    children: [
        //Mechanical work
        //Work worksheet, lots of basic numerical
        {ID:88,type:"ws"},//Energy: W = F s cosθ

        //Gravitational Potential Energy
        //Kinetic energy
        //Law of conservation of energy in a closed system
        {ID:91,type:"ws"},//Energy: law of conservation
        {ID:90,type:"ws"},//Energy: efficiency

        //Power
        {ID:89,type:"ws"},//Energy: power

    ]
},
{
    topic_name:"Momentum",
    T_ID:59,
    children: [
        {ID:81,type:"ws"},//Momentum - non-continuous
        
        //Linear Momentum
        //TODO - basic GCSE level momentum module
        //Change in momentum and net force
        //Law of conservation of momentum
    ]
},
{
    topic_name:"Uniform circular motiom",
    status:"empty",
    T_ID:60,
    children: [
    
    ]
},
{
    topic_name:"Gravitation",
    status:"empty",
    T_ID:61,
    children: [
    
    ]
},
{
    topic_name:"Nature and properties of waves",
    T_ID:62,
    children: [
        //Nature of waves
        {ID:19,type:"ws"},//	Wave properties GCSE
        //Wave motion and propagation
        {ID:21,type:"ws"},//	Waves booklet 1: Transverse and longitudinal waves
        {ID:23,type:"ws"},//	Waves booklet 2: Time period and frequency
        {ID:24,type:"ws"},//	Waves booklet 3: The wave equation


        //Reflection and refraction
        {ID:145,type:"ws"},//Reflection
        {ID:146,type:"ws"},//Refraction

        //Diffraction and interference
        {ID:149,type:"ws"},//Diffraction
        {ID:133,type:"ws"},//Young's double slit
        //I think here is non-mathematical diffraction
        {ID:155,type:"ws"},//Diffraction equations

        //Stationary wave (transverse only)
    ]
},
{
    topic_name:"Light",
    T_ID:63,
    children: [
        //Light in electromagnetic spectrum
        {ID:28,type:"ws"},//Waves booklet 7: Electromagnetic waves

        //Reflection of light
        {ID:145,type:"ws"},//Reflection

        //Refraction of light
        {ID:146,type:"ws"},//Refraction

        //Total internal reflection
        {ID:147,type:"ws"},//Total internal reflection

        //Formation of images by lenses
        //Wave nature of light
        {ID:133,type:"ws"},//Young's double slit
        {ID:155,type:"ws"},//Diffraction equations

    ]
},
{
    topic_name:"Sound",
    T_ID:64,
    status:"empty",
    children: [
        //Wave nature of sound
        //Audible frequency range
        //Musical notes
        //Noise
    ]
},
{
    topic_name:"Electrostatics",
    T_ID:65,
    children: [
        //Electric charges (they go on to include Coulomb's law calcs)
            {ID:18,type:"ws"},
            {ID:9,type:"ws"},

        //Electric field
    ]
},
{
    topic_name:"Circuits and domestic electricity",
    T_ID:66,
    children: [
        //Electric current
            {ID:103,type:"ws"},//Current and charged particles quantitativ
            //basic current concept?

        //Electrical energy and emf
            {ID:105,type:"ws"},// ✎ Electricity key terms

        //Resistance
            {ID:106,type:"ws"},// ✎ V = IR qualitative
            //resistivity
            {ID:112,type:"ws"},// ✎ Current and temperature
        //Series and parallel circuits
            {ID:107,type:"ws"},// ✎ Spotting components in series and parallel
            {ID:108,type:"ws"},// ✎ Combining resistors in series and parallel
            //Derivation of the rules
            {ID:104,type:"ws"},// ✎ Basic Voltage Rules
        //Simple circuits
            {ID:113,type:"ws"},//Potential divider
            {ID:123,type:"ws"},//Emf and internal resistance
            {ID:126,type:"ws"},//Potentiometer

        //Electrical power
        //{ID:124,type:"ws"},//Superconductors

        //Domestic electricity
    ]
},
{
    topic_name:"Electromagnetism",
    T_ID:67,
    status:"empty",
    children: [
        //Magnetic force and magnetic field
        //Magnetic effect on electric current
        //Force due to a magnetic field
        //Electromagnetic induction
        //Alternating current
        {ID:151,type:"ws"},//Alternating current

        //Transformer
        //High voltage transmission of electrical energy

    ]
},
{
    topic_name:"Radiation and radioactivity",
    T_ID:68,
    children: [
        //X-rays - group coming soon
        //α, β and γ radiations
        {ID:34,type:"ws"},//Radioactivity 1: atomic structure and isotopes KS4,5
        {ID:35,type:"ws"},//Radioactivity 2: what is nuclear radiation KS4,5
            //behaviour in different fields. 
        {ID:38,type:"ws"},//Radioactivity 5:uses KS4,5

        //radioactive decay
        {ID:55,type:"ws"},//Atomic Structure Extra KS4,5
        {ID:58,type:"ws"},//	Nuclear decay KS5
        {ID:36,type:"ws"},//Radioactivity 3: Half life KS4,5


        //detection of radiation
        {ID:37,type:"ws"},//Radioactivity 4: Practical work KS4,5
        {ID:97,type:"ws"},//Nuclear and Radioactivity: Experimental and safety
    ]
},
{
    topic_name:"Atomic model",
    T_ID:69,
    children: [
        {ID:34,type:"ws"},//Radioactivity 1: atomic structure and isotopes KS4,5
        {ID:55,type:"ws"},//	Atomic structure Extra KS4,5
        {ID:56,type:"ws"},//	Atomic structure and specific charge KS5
        {ID:96,type:"ws"},//	α, β and γ radiation
        
    ]
},
{
    topic_name:"Nuclear energy ",
    T_ID:70,
    children: [
        {ID:27,type:"grp"},//KS5 Nuclear Energy
    
    ]
},
{
    topic_name:"The universe as seen in different scales",
    T_ID:71,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Astronomy through history",
    T_ID:72,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Orbital motions under gravity",
    T_ID:73,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Stars and the universe",
    T_ID:74,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Rutherford’s atomic model",
    T_ID:75,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Photoelectric effect",
    T_ID:76,
    children: [
     {ID:38,type:"grp"}//KS5 photoelectric effect group
    ]
},
{
    topic_name:"Bohr’s atomic model of hydrogen",
    T_ID:77,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Particles or waves",
    T_ID:78,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Probing into nano scale",
    T_ID:79,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Electricity at home",
    T_ID:80,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Energy efficiency in building and transportation",
    T_ID:81,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Renewable and non-renewable energy sources",
    T_ID:82,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Making sense of the eye and the ear",
    T_ID:83,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Medical imaging using non-ionizing radiation",
    T_ID:84,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Medical imaging using ionizing radiation",
    T_ID:85,
    status:"empty",
    children: [
    
    ]
},
{
    topic_name:"Temperature and thermometers - in construction",
    T_ID:86,
    status:"empty",
    children: [
        //TODO make worksheet on thermometers
    ]
},
{
    topic_name:"Heat and internal energy",
    T_ID:87,
    children: [
        {ID:50,type:"ws"},//Heating and cooling - Temperature vs Thermal energy
        {ID:51,type:"ws"},//Heating - hot to cold
        {ID:69,type:"ws"},//	Internal Energy Alevel

    ]
},
{
    topic_name:"Heat capacity and specific heat capacity",
    T_ID:88,
    children: [
        {ID:86,type:"grp"},
        {ID:82,type:"ws"},//	Specific heat capacity

    ]
},
	{
    topic_name:"KS4 Practical skills",
    T_ID:90,
    children: [
        {ID:263,type:"ws"},//	Hazards, risks and precautions
        {ID:264,type:"ws"},//	Resolution of ammeters
        {ID:265,type:"ws"},//	Errors and validity
    ]
},
	{
    topic_name:"Electricty - energy and power calculations",
    T_ID:91,
    children: [
	{ID:273,type:"ws"},//	E = Q V calculations - level 1
	{ID:274,type:"ws"},//	E = Q V calculations - level 2
	{ID:275,type:"ws"},//	E = Q V calculations - level 3
	{ID:276,type:"ws"},//	P = I V calculations - level 1
	{ID:277,type:"ws"},//	P = I V calculations - level 2
	{ID:278,type:"ws"},//	P = I V calculations - level 3
	{ID:279,type:"ws"},//	P = E / t calculations - level 1
	{ID:280,type:"ws"},//	P = E / t calculations - level 2
	{ID:281,type:"ws"},//	P = E / t calculations - level 3
	{ID:282,type:"ws"},//	P = I^2 R calculations - level 1
	{ID:283,type:"ws"},//	P = I^2 R calculations - level 2
	{ID:284,type:"ws"},//	P = I^2 R calculations - level 3
	{ID:295,type:"ws"},//	Mixed electricity calculations - level 2
	{ID:296,type:"ws"},//	Mixed electricity calculations - level 3
	{ID:285,type:"ws"},//	Electrical efficiency calculations - level 1
	{ID:286,type:"ws"},//	Electrical efficiency calculations - level 2
    ]
},
//{
//    topic_name:"KS4 Chemistry",
//    T_ID:92,
//    children: [
//        {ID:288,type:"ws"},//	Atom economy
//        {ID:289,type:"ws"},//	Percentage yield
//    ]
//},
]
/*
KS3 Science
    0 Variables
    Force and motion
        12  Forces 1 
        41	Forces 1 - Identifying forces
        13	Forces 2 - Magnets
        14	Forces 3 - Normal contact force
        17	Forces 4 - Unbalanced forces
        18	Forces 5 - Electrostatics
        42	Adding forces in 1D
        43	Effect of resultant force
        45	Mass vs Weight
    KS3 SLG Solids, liquids, gases
        5   SLG Intro
        46	SLG - three states of matter
        47	SLG - particle model
        48	SLG - changing state
        49	SLG - changing state - particle model
    Electricity
        100	Basic Current Rules
KS4 Physics
    Numerical Skills
        15	Equations GCSE Physics
        16	GCSE Physics eqns flashcards
        20	Numerical revision mix combined working required
    Electricity
        101	Current = Q / t
        102	Nature of electrical conductors
        104	Basic Voltage Rules
        105	Electricity key terms
        106	V = IR qualitative
        107	Spotting components in series and parallel
        108	Combining resistors in series and parallel
        109	V = IR quantitative
        110	Circuit component symbols worksheet
        112	Current and temperature
        80	Electricity Intro Quiz
    Electrostatics
        18	Forces 5 - Electrostatics
        9   Electrostatics KS4
    SLG Particle model
        KS3 SLG
        5 SLG Intro
        50	Heating and cooling - Temperature vs Thermal energy
        51	Heating - hot to cold
        52	Heating - types of heating
        53	Heating - thermal expansion and convection
        54	Heating - conduction
    Nuclear
        7 Atomic structure
        8 Application of radiation
        34	Radioactivity 1: atomic structure and isotopes KS4,5
        35	Radioactivity 2: what is nuclear radiation KS4,5
        36	Radioactivity 3: Half life KS4,5
        37	Radioactivity 4: Practical work KS4,5
        38	Radioactivity 5: uses KS4,5
        55	Atomic structure Extra KS4,5
    Forces and motion
        KS3 Forces
        KS5 Mechanics
    Energy
        30	Energy transfers GCSE
    Waves
        19	Wave properties GCSE
        21	Waves booklet 1: Transverse and longitudinal waves
        23	Waves booklet 2: Time period and frequency
        24	Waves booklet 3: The wave equation
        25	Waves booklet 4: Ripple tank
        28	Waves booklet 7: Electromagnetic waves
        29	Waves booklet 8: Uses EM waves
        32	Wave booklet 9: IR
    Magnetism and electromagnetism
    Astrophysics
KS5 Physics
    KS5 Definitions
        31	A-level waves definition
        73	A-level definitions - Mechanics
        72	A-level definitions - Materials
    Measurements and their errors
        79	Significant figures
        99	Materials - Practical terminology
        77	Materials- Young Modulus practical
        97	Nuclear and Radioactivity: Experimental and safety
    Quantum and nuclear 1
        56	Atomic structure and specific charge KS5
        57	Strong nuclear force KS5
        58	Nuclear decay KS5
        59	Antiparticles KS5
        60	Pair production and annihilation KS5
        62	Particle classification KS5
        63	Mesons KS5
        64	Leptons KS5
        65	Quarks KS5
        66	Quarks and mesons KS5
        67	Quantum numbers and interactions KS5
        68	strangeness KS5
    Nuclear 2
        92	Rutherford scattering
        93	Size of the nucleus
        94	Nuclear density
        95	Investigating atomic structure by diffraction
        96	α, β and γ radiation
        97	Nuclear and Radioactivity: Experimental and safety
        98	Inverse square law
    Waves
        Waves KS4
        31	A-level waves definition
    Mechanics 1
        61	Vectors and scalars
        78	Dynamics - Terminal velocity
        81	Momentum - non-continuous
        87	Momentum continuous flow
        88	Energy: W = F s cosθ
        89	Energy: power
        90	Energy: efficiency
        91	Energy: law of conservation
        73	A-level definitions - Mechanics
    Mechanics 2
    Materials
        74	Materials- Hooke's law
        75	Material- Springs and energy
        76	Materials- Young Modulus and stress / strain graph
        99	Materials - Practical terminology
        77	Materials- Young Modulus practical
        72	A-level definitions - Materials
    Electricity
        Electricity KS4
        103	Current and charged particles quantitative
        113	Potential divider
    SLG Thermal
        69	Internal Energy Alevel
        82	Specific heat capacity
        83	Specific latent heat
        84	Absolute zero of temperature
        85	Molecular kinetic theory model
        86	Relationships between p, V and T in terms of a simple molecular model.
    Force Fields
        71	Newton's law of gravitation
    Magnetism and electromagnetism
    Turning points


KS3 Science
    0 Variables
    Force and motion
        12  Forces 1 
        41	Forces 1 - Identifying forces
        13	Forces 2 - Magnets
        14	Forces 3 - Normal contact force
        17	Forces 4 - Unbalanced forces
        18	Forces 5 - Electrostatics
        42	Adding forces in 1D
        43	Effect of resultant force
        45	Mass vs Weight
    Solids, liquids, gases
        5   SLG Intro
        46	SLG - three states of matter
        47	SLG - particle model
        48	SLG - changing state
        49	SLG - changing state - particle model
    Electricity
        100	Basic Current Rules
KS4 Physics
    Skills
        Numerical
            15	Equations GCSE Physics
            16	GCSE Physics eqns flashcards
            20	Numerical revision mix combined working required
    Electricity
        101	Current = Q / t
        102	Nature of electrical conductors
        104	Basic Voltage Rules
        105	Electricity key terms
        106	V = IR qualitative
        107	Spotting components in series and parallel
        108	Combining resistors in series and parallel
        109	V = IR quantitative
        110	Circuit component symbols worksheet
        112	Current and temperature
        80	Electricity Intro Quiz
    Electrostatics
        18	Forces 5 - Electrostatics
        9   Electrostatics KS4
    SLG Particle model
        KS3 SLG
        5 SLG Intro
        50	Heating and cooling - Temperature vs Thermal energy
        51	Heating - hot to cold
        52	Heating - types of heating
        53	Heating - thermal expansion and convection
        54	Heating - conduction
    Nuclear
        Atomic structure 7
        Application of radiation 8
        34	Radioactivity 1: atomic structure and isotopes KS4,5
        35	Radioactivity 2: what is nuclear radiation KS4,5
        36	Radioactivity 3: Half life KS4,5
        37	Radioactivity 4: Practical work KS4,5
        38	Radioactivity 5: uses KS4,5
        55	Atomic structure Extra KS4,5
    Forces and motion
        KS3 Forces
        KS5 Mechanics
    Energy
        30	Energy transfers GCSE
    Waves
        19	Wave properties GCSE
        21	Waves booklet 1: Transverse and longitudinal waves
        23	Waves booklet 2: Time period and frequency
        24	Waves booklet 3: The wave equation
        25	Waves booklet 4: Ripple tank
        28	Waves booklet 7: Electromagnetic waves
        29	Waves booklet 8: Uses EM waves
        32	Wave booklet 9: IR
    Magnetism and electromagnetism
    Astrophysics
KS5 Physics
    Definitions
        31	A-level waves definition
        73	A-level definitions - Mechanics
        72	A-level definitions - Materials
    Measurements and their errors
        79	Significant figures
        99	Materials - Practical terminology
        77	Materials- Young Modulus practical
        97	Nuclear and Radioactivity: Experimental and safety
    Quantum
        56	Atomic structure and specific charge KS5
        57	Strong nuclear force KS5
        58	Nuclear decay KS5
        59	Antiparticles KS5
        60	Pair production and annihilation KS5
        62	Particle classification KS5
        63	Mesons KS5
        64	Leptons KS5
        65	Quarks KS5
        66	Quarks and mesons KS5
        67	Quantum numbers and interactions KS5
        68	strangeness KS5
    Nuclear
        92	Rutherford scattering
        93	Size of the nucleus
        94	Nuclear density
        95	Investigating atomic structure by diffraction
        96	α, β and γ radiation
        97	Nuclear and Radioactivity: Experimental and safety
        98	Inverse square law
    Waves
        Waves KS4
        31	A-level waves definition
    Mechanics 1
        61	Vectors and scalars
        78	Dynamics - Terminal velocity
        81	Momentum - non-continuous
        87	Momentum continuous flow
        88	Energy: W = F s cosθ
        89	Energy: power
        90	Energy: efficiency
        91	Energy: law of conservation
        73	A-level definitions - Mechanics
    Mechanics 2
    Materials
        74	Materials- Hooke's law
        75	Material- Springs and energy
        76	Materials- Young Modulus and stress / strain graph
        99	Materials - Practical terminology
        77	Materials- Young Modulus practical
        72	A-level definitions - Materials
    Electricity
        Electricity KS4
        103	Current and charged particles quantitative
        113	Potential divider
    SLG Thermal
        69	Internal Energy Alevel
        82	Specific heat capacity
        83	Specific latent heat
        84	Absolute zero of temperature
        85	Molecular kinetic theory model
        86	Relationships between p, V and T in terms of a simple molecular model.
    Force Fields
        71	Newton's law of gravitation
    Magnetism and electromagnetism
    Turning points

    */

    export interface TreeNode {
        label:string,
        value:number,
        children?:TreeNode[],
        disabled?:boolean,
        className?:string,
        checked?:boolean,
        isws:boolean,
        expanded?:boolean,
        ID?:string,//of the form 0.3.34.54 (identifies the path to that node)
    }
    
    export function getexercisestree(worksheets:Worksheet[],topic_table:Topic[],assignedlist:Assignment[],groupsexpanded?:string[]){
        let exercisestree:TreeNode[] = [];
        processagroup(topic_table[0],exercisestree,topic_table,[],worksheets,assignedlist,groupsexpanded);
        let output = exercisestree[0].children;
        if(output===undefined){
            throw "output undefined in getexercisestree"
        } else {
            return output;//don't need 'topics' at top level.
        }
    }
    
    function processagroup(topic:Topic, treenodechildren:TreeNode[], topic_table:Topic[], parentlist:number[], worksheets:Worksheet[],assignedlist:Assignment[],groupsexpanded:string[]|undefined){
        //we know that they are a group not a ws at this point
        let children:TreeNode[] = [];
        for(let c = 0; c<topic.children.length; c++){
            let child_ID = topic.children[c].ID;
            let child_type = topic.children[c].type;
            if(child_type==="grp"){
                let child = topic_table.find((group:Topic)=>{return group.T_ID===child_ID});
                if(child===undefined) {console.log("missing group: "+child_ID);
                //throw "missing group"
                } else{
                    if(parentlist.findIndex((p:number)=>p===child_ID)>-1){
                        throw "circular reference with grp: "+child_ID+JSON.stringify(parentlist)
                    }
                    let newparentlist:number[] = [...parentlist,child_ID];
                    processagroup(child, children,topic_table,newparentlist,worksheets,assignedlist,groupsexpanded);   
                }
            }
			else if(child_type==="ws"){
				let worksheet = worksheets.find((w:Worksheet)=>{return w.ID===child_ID})
                if (worksheet===undefined){
                    //	console.log("ws "+child_ID.toString()+" not found")
                } else {
                    let W_ID = worksheet.ID;
                    let isassigned = assignedlist.findIndex((a:Assignment)=>a.W_ID===W_ID)>-1
                    children.push(
                        {
                            label:worksheet.name,
                            value:worksheet.ID,
                            checked:isassigned,
                            isws:true,
                        }
                    )
                }
            }
        }
        let expanded = false;
        let unique_ID = parentlist.join(".")+"."+topic.T_ID;
        if(groupsexpanded!==undefined){
            expanded = groupsexpanded.indexOf(unique_ID)>-1
        }
        treenodechildren.push(
            {
                label:topic.topic_name,
                value:topic.T_ID,
                children:children,
                disabled:true,
                className:"parentindropdownselect",
                isws:false,
                expanded:expanded,
                ID:unique_ID,
            }
        )
    }
