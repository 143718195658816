import { PupilIDs, PupilNames, TeacherIDs } from "../../../../my_interfaces";
import { APIfetchclassuserdata } from "./UserManagerList";

export async function resetPassword(U_ID: Number,Class_ID:Number): Promise<string|false> {//{success:boolean,currentpasswordcorrect:boolean,newpassword:string} success:true,currentpasswordcorrect:true,newpassword:returnpassword?newpassword:false});
	console.log('reseting password for Q_ID, Class_ID, pupil_index'+U_ID+" "+Class_ID);
	var url = '/resetpassword';
	var data = {U_ID,Class_ID};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj.success?js_obj.newpassword:false;
}
export async function unlockUser(U_ID: Number,Class_ID:Number): Promise<boolean> {//{success:boolean,currentpasswordcorrect:boolean,newpassword:string} success:true,currentpasswordcorrect:true,newpassword:returnpassword?newpassword:false});
	console.log('unlocking user for Q_ID, Class_ID, pupil_index'+U_ID+" "+Class_ID);
	var url = '/resetpassword';
	var data = {U_ID,Class_ID,action:"unlock"};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj.success;
}
export async function removeUserFromClass(U_ID: Number,Class_ID:Number): Promise<boolean> {//{success:boolean,currentpasswordcorrect:boolean,newpassword:string} success:true,currentpasswordcorrect:true,newpassword:returnpassword?newpassword:false});
	console.log('removing user for Q_ID, Class_ID, pupil_index'+U_ID+" "+Class_ID);
	var url = '/resetpassword';//yes, I should change the name of this.
	var data = {U_ID,Class_ID,action:"remove"};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj.success;
}
export async function promoteUserFromClass(U_ID: Number,Class_ID:Number): Promise<boolean> {//{success:boolean,currentpasswordcorrect:boolean,newpassword:string} success:true,currentpasswordcorrect:true,newpassword:returnpassword?newpassword:false});
	console.log('promoting user for Q_ID, Class_ID, pupil_index'+U_ID+" "+Class_ID);
	var url = '/resetpassword';//yes, I should change the name of this.
	var data = {U_ID,Class_ID,action:"promote"};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj.success;
}

export async function fetchClassPupilData(Class_ID: number): Promise<[PupilNames[], (string | null | undefined)[], PupilIDs[], TeacherIDs[]]> {
	let response = await fetch('/fetchclassuserdata', {
	  method: 'POST',
	  body: JSON.stringify({
		Class_ID,
	  }),
	  headers: { 'Content-Type': 'application/json' }
	});
  
	let js_obj:APIfetchclassuserdata = await response.json();
  
	return [js_obj.pupilsnames, js_obj.temppasswords, js_obj.pupilsIDs, js_obj.usersIDs];
  }
  