import React from "react";
import {Theme} from "@mui/material";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme: Theme) => ({
    overrideBackground: {
        backgroundColor: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "block",
        zIndex: -1,
    }
}));


function OverrideBackground() {
    const classes = useStyles();

    return (
        <div className={classes.overrideBackground}/>
    )
}

export default OverrideBackground;
