import React from 'react';
import { Divider, Theme, CssBaseline, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import {QuotesSection} from "./QuotesSection";
import {LandingPageProps, QuotesSectionProps} from "./_LandingPageInterfaces";
import FaqSection from "./FaqSection";
import HeroSection from "./HeroSection";
import InfoSection from "./InfoSection";
import * as IMAGES from '../../constants/images';


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    bodyColorCorrection: {
        backgroundColor: "white",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "block",
        zIndex: -1,
    },
    pageContainer: {
        maxWidth: "1300px",
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('lg')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.between("sm", 1500)]: {
            marginLeft: theme.spacing(10),
            marginRight: theme.spacing(10),
        },
        [theme.breakpoints.up(1500)]: {
            margin: "0 auto !important",
        }
    },
    divider: {
        marginTop: theme.spacing(8)
    },
    icon: {
        height: "40px",
        width: "40px",
        color: theme.palette.primary.dark,
    },
    Title: {
        textAlign: "center",
        marginTop:"30px",
    },
}));


function LandingPage({user, handleLogout}: LandingPageProps) {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    // REF_58484
    const quotes: QuotesSectionProps["quotes"] = [
        {
            name: "Henry Hammond",
            caption: "Winner of IOP ‘2020 Teacher of Physics Awards’",
            body: "What makes MMM stand out from other online learning is the iterative directing to answers."
        },
   //     {
   //         name: "Jed Marshall",
   //         caption: "Physics Teacher",
   //         body: "I must say I am very impressed with the website - I've explored similar auto-marked things in the past and this is a cut above."
   //     },
        {
            name: "Year 10 Student",
            caption: "",
            body: "It was definitely more useful than some forms of revision/homework, so I'd be happy to use this again in future."
        },
        {
            name: "Mr Kerr",
            caption: "Head of Physics",
            body: "I liked the fact that I did not have to spend time marking, but instead, I spent more time looking at individual pupils and the kinds of mistakes they were making."
        },
    //    {
    //        name: "David",
    //        caption: "Trainee Physics Teacher",
    //        body: "I think the programme is excellent particularly the journey it offers to get the pupils to a correct answer."
    //    },
        
    //    {
    //        name: "Y10 Student",
    //        caption: "",
    //        body: "I think that it is a very good way to revise as it repeats the questions you needed help with at the end, making memorising answers a lot easier."
    //    }
    ];
    let infotext1 = ["Eliminate ‘silly errors’","Lay out working like a pro","Solve with ease!"];
    let infotext2 = ["Know what points to make and how to make them","Give the best answer","Get maximum marks"];
    //let infotext3 = ["Improve instantly, ","See your improvement,","Only practise what you need to,"]
    let infotext4 = ["24/7 AI support for pupils at home","Save hours of marking and admin","Identify key points to address before the lesson","Easily track missing work"]
    let infotext5 = ["Investigate answers by question or by pupil","See each pupil develop their answer: red → yellow → green","Share answers anonymously for class discussion or public praise"]
    let infotext6 = ["Monitor live progress","Target interventions in real-time","Free up time for these interventions as the tool gives the first level of support automatically"]
    return (
        <CssBaseline>
            <div className={classes.bodyColorCorrection}/>
            <div className={classes.pageContainer}>
                <HeroSection user={user} handleLogout={handleLogout}/>
                <Divider variant="middle" className={classes.divider}/>
                <Typography variant="h3" className={classes.Title}>
                    For Students
                </Typography>
                <InfoSection // REF_74234
                    title="Numerical questions"    // REF_46840
                    body={infotext1}   // REF_30707
                    image={IMAGES.NUMERICAL_QUESTIONS}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=pwAXJMtlpng", "_blank")}   // REF_56480
                    buttonText="Learn More"     // REF_59305
                    textOnLeft={true}
                    icon={<SchoolIcon className={classes.icon}/>}
                />
                <InfoSection
                    title="Longer questions"   // REF_56248
                    body={infotext2}// REF_74336
                    image={IMAGES.LONGER_QUESTIONS}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=bALhrAKwT6g", "_blank")}   // REF_35582
                    buttonText="See How"    // REF_31404
                    textOnLeft={false}
                    icon={<PeopleIcon className={classes.icon}/>}
                />
                {/*    <InfoSection // REF_74234
                    title="Save time"    // REF_46840
                    body={infotext3}   // REF_30707
                    image={IMAGES.SAVE_TIME}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=pwAXJMtlpng", "_blank")}   // REF_56480
                    buttonText="Learn More"     // REF_59305
                    textOnLeft={true}
                    icon={<SchoolIcon className={classes.icon}/>}
                />*/}
                <Divider variant="middle" className={classes.divider}/>
                <Typography variant="h3" className={classes.Title}>
                    For Teachers
                </Typography>
                <InfoSection
                    title="For homework"   // REF_56248
                    body={infotext4}// REF_74336
                    image={IMAGES.FOR_HOMEWORK}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=bALhrAKwT6g", "_blank")}   // REF_35582
                    buttonText="See How"    // REF_31404
                    textOnLeft={true}
                    icon={<PeopleIcon className={classes.icon}/>}
                />
                <InfoSection // REF_74234
                    title="Dive deeper "    // REF_46840
                    body={infotext5}   // REF_30707
                    image={IMAGES.DIVE_DEEPER}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=pwAXJMtlpng", "_blank")}   // REF_56480
                    buttonText="Learn More"     // REF_59305
                    textOnLeft={false}
                    icon={<SchoolIcon className={classes.icon}/>}
                />
                <InfoSection
                    title="In class"   // REF_56248
                    body={infotext6}// REF_74336
                    image={IMAGES.IN_CLASS}
                //    onClick={() => window.open("https://www.youtube.com/watch?v=bALhrAKwT6g", "_blank")}   // REF_35582
                    buttonText="See How"    // REF_31404
                    textOnLeft={true}
                    icon={<PeopleIcon className={classes.icon}/>}
                />
                <Divider variant="middle" className={classes.divider}/>
                <FaqSection/>
                <Divider variant="middle" className={classes.divider}/>
                <QuotesSection quotes={quotes}/>
            </div>
        </CssBaseline>
    )
}

export default LandingPage;
