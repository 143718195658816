import React from 'react';
import { Avatar, Button, Grid, Theme, Typography, GridDirection } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as COLORS from "@mui/material/colors";
import YouTubeIcon from '@mui/icons-material/YouTube';
import {InfoSectionProps} from "./_LandingPageInterfaces";


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: "#202124",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            textAlign: "center",
        }
    },
    avatar: {
        // This is the circle in which the icon is displayed
        marginTop: "-55px",
        marginBottom: theme.spacing(1),
        height: "55px",
        width: "55px",
        backgroundColor: COLORS.grey[200],
        [theme.breakpoints.down('lg')]: {
            margin: "auto"
        }
    },
    container: {
        marginTop: theme.spacing(10),
    },
    contentContainer: {
        margin: "auto",
    },
    buttonContainer: {
        [theme.breakpoints.down('lg')]: {
            margin: "auto",
        }
    },
    button: {
        ...theme.typography.h6,
        fontSize: 16,
        letterSpacing: 0.8,
        textTransform: "none",
        marginTop: theme.spacing(2),
    },
    text: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        //    textAlign: "center",
        }
    },
    image: {
        maxWidth: "100%",
        margin: "auto"
    },
}));

function InfoSection({title, body, image, /*onClick,*/ buttonText, textOnLeft, icon}: InfoSectionProps) {
    //when props are here one can Ctrl+Click on it to jump to where it is used.
    // REF_69160
    // This component displays some text and a button alongside a relevant image.
    // Currently being used for showing features of the website.
    //
    //      title: string - The header for this section
    //
    //      body: string - The body text for this section
    //
    //      image: string - A reference to where the image is stored, normally stored in "constants/images.ts"
    //
    //      onClick: () => void - The function called when the button is clicked
    //
    //      buttonText: string - The text displayed on the button
    //
    //      textOnLeft: boolean - Whether to display the text on the left or on the right
    //
    //      icon: React.ReactNode - The icon to display above the text

    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    let gridDirection: GridDirection;
    if (textOnLeft) {
        gridDirection = "row";
    } else {
        gridDirection = "row-reverse";
    }
    let bodylist=[];
    for(let i = 0 ; i<body.length; i++){
        bodylist.push(<li 
        //    style={{marginTop:"10px"}}
            >{body[i]}</li>);
    }
    return (
        <div className={classes.container}>
            <Grid container direction={gridDirection} spacing={7}>
                <Grid item xs={12} md={6} className={classes.contentContainer}>
                    <Grid container direction="column">
                    {/*    <Grid item>
                            <Avatar className={classes.avatar}>
                                {icon}
                            </Avatar>
                        </Grid>
                    */}
                        <Grid item>
                            <Typography variant="h4" component="h3" className={classes.text}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" className={classes.text}>
                                <ul>
                                    {bodylist}
                                </ul>
                            </Typography>
                        </Grid>
                    {/*}    <Grid item className={classes.buttonContainer}>
                            <Button variant="outlined" color="primary" className={classes.button} size="large" onClick={onClick} endIcon={<YouTubeIcon/>}>
                                {buttonText}
                            </Button>
    </Grid>*/}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img 
                        src={image} 
                        className={classes.image} 
                        style={{
                            borderRadius: "0.75em",
                            boxShadow: "0.5em 0.5em 0.5em lightgrey",
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default InfoSection;
