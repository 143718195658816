import React from 'react';
import { Assignment, Worksheet } from '../../../../my_interfaces';
import './AssignmentRow.css';  // Importing the CSS file

interface AssignmentRowProps {
    assignment: Assignment;
    worksheet: Worksheet;
    islive: boolean;
    onAssignmentButtonClick: () => void;
    onToggleLive: (islive: boolean) => void;
}

const AssignmentRow: React.FC<AssignmentRowProps> = ({
    assignment,
    worksheet,
    islive,
    onAssignmentButtonClick,
    onToggleLive,
}) => (
    <div className="assignment-row">
        <div className="assignment-button-container">
            <button className="markbookbutton" onClick={onAssignmentButtonClick}>
                {assignment.name}
            </button>
        </div>
        <div className="assignment-toggle-container">
            <label className="switch">
                <input
                    className="slider"
                    type="checkbox"
                    checked={islive}
                    onChange={(e) => onToggleLive(!islive)}
                />
                <span className="slider"></span>
            </label>
        </div>
    </div>
);

export default AssignmentRow;
