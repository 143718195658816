import React, {useEffect} from "react";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
    Avatar,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Theme,
    Tooltip,
    Typography,
    CssBaseline,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {RegisterPageProps} from "./_RegisterPageInterfaces";
import {OverrideBackground, LoadingButton, State} from "../Utils";
import {EMAIL_REGEX} from "../../constants/constants";
import * as PATHS from "../../constants/paths";
import {checkPassStrength} from "../../shared_functions";
import {isFieldvalueNew, Login} from "../../../App";



// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    link: {
        cursor: "pointer",
        userSelect: "none",     // This prevents the text from being selected https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
    },
}));


function RegisterPage({onRegisterSuccess, handleLogin, cookiesdisabled, history}: RegisterPageProps) {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    // Stores the Username entered into the text field
    const [username, setUsername] = State<string>("");

    // Stores the Password entered into the text field
    const [password, setPassword] = State<string>("");

    // Stores the Password entered into the text field
    const [confirmPassword, setConfirmPassword] = State<string>("");

    // Stores the First Name entered into the text field
    const [firstName, setFirstName] = State<string>("");

    // Stores the Last Name entered into the text field
    const [lastName, setLastName] = State<string>("");

    // Stores the Class Code entered into the text field
    const [classCode, setClassCode] = State<string>("");

    // Stores the Email Address entered into the text field
    const [email, setEmail] = State<string>("");

    // Stores whether the process failed or not
    const [fail, setFail] = State<boolean>(false);

    // Stores whether the account is a teacher
    const [isTeacher, setIsTeacher] = State<boolean>(false);

    // Stores the message that tells the user what went wrong, these fields will then
    // be applied to their respective text-field in the `helperText` property
    const [errors, setErrors] = State<{username: string, password: string, firstName: string, lastName: string, classCode: string, email: string, confirmPassword: string}>({
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        classCode: "",
        email: "",
        confirmPassword: ""
    });

    // Handles the button loading wheel
    const [loading, setLoading] = State<boolean>(false);
    const [success, setSuccess] = State<boolean>(false);

    // This function is used to generate a function that will be passed to the OnChange property
    // of the text fields. To use it, pass one of the React state functions to it.
    const onChange = (func: Function) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => func(e.target.value);
    }

    const onCheckboxChange = (func: Function) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => func(e.target.checked);
    }

    // These functions will check their respective field to make sure it is valid, some of these functions are async
    // so rather than returning a boolean of the validity and a string of the reason, they return a promise of the values.
    const validateField = {
        password: (inputValue: string): [boolean, string] => {
            // This function will check the supplied password to determine whether is passes the security checks.
            // We can add any logic we want here, as long as it returns a boolean of whether its true or not as well
            // as a string telling the user why it is not valid.

            let strength = checkPassStrength(inputValue);

            // Check Password Validity
            if( !(inputValue.length >= 8) )
                return [false, "Password is too short"];

            else if( !isTeacher && (strength === "too weak") )
                return [true, "Password is weak but valid"];

            else if( !(strength === "strong" || strength === "good" || strength === "ok") )
                return [false, "Password is "+ strength];

            return [true, ""]
        },
        firstName: (inputValue: string): [boolean, string] => {
            // Currently the only validation that needs to be done for the names is to check if they actually have
            // a value, in theory this should never be necessary as the button can't be clicked when the fields are
            // empty, but it just adds another layer of protection.

            if (inputValue === "") return [false, "Field can't be empty"];
            else return [true, ""];
        },
        lastName: (inputValue: string): [boolean, string] => {
            // Read previous comment

            if (inputValue === "") return [false, "Field can't be empty"];
            else return [true, ""];
        },
        username: (inputValue: string): Promise<[boolean, string]> => {
            // Because we need to validate the username with the backend, this function must be async, so it returns a
            // Promise rather than an actual value. The validation we do consists of:
            // 1. Field has a value
            // 2. Username is less than 40 characters
            // 3. Username is greater or equal to 6 characters
            // 4. Checking it is not an email
            // 5. Asking the backend whether the value already exists as a username

            return isFieldvalueNew('username', username)
                .then(result  => {
                    if (inputValue === "") return [false, "Field can't be empty"];
                    if (inputValue.length > 40) return [false, "Username is too long"];
                    if (inputValue.length < 6) return [false, "Username is too short"];
                    if (EMAIL_REGEX.test(inputValue)) return [false, "Just the bit before the @"];

                    if (!result) return [false, "Username already exists"];
                    else return [true, ""]
                })
        },
        email: (inputValue: string): Promise<[boolean, string]> => {
            // Because we need to validate the email with the backend, this function must be async, so it returns a
            // Promise rather than an actual value. The validation we do consists of:
            // 1. Field has a value
            // 2. Email has no spaces within it
            // 3. That it is a valid email (at least according to the regex)
            // 4. Asking the backend whether the value already exists as a email

            return isFieldvalueNew('emailAddress', inputValue)
                .then(result => {
                    if (inputValue === "") return [false, "Field can't be empty"];
                    if (inputValue.search(" ") > -1) return [false, "Spaces in or around email"];
                    if (!EMAIL_REGEX.test(inputValue)) return [false, "Not a valid email"];

                    if (!result) return [false, "Email already exists"];
                    else return [true, ""]
                })
        },
        classCode: (inputValue: string): Promise<[boolean, string]> => {
            // Because we need to validate the class code with the backend, this function must be async, so it returns a
            // Promise rather than an actual value. The validation we do consists of:
            // 1. Field is empty (therefore automatic pass)
            // 2. Less than 20 characters
            // 3. That it exists on the backend

            return isFieldvalueNew('classcode', inputValue)
                .then(result => {
                    if (inputValue === "") return [true, ""];
                    if (inputValue.length >= 20) return [false, "Class Code too long"];

                    if (result) return [false, "Class Code does not exist"];
                    else return [true, ""]
                })
        },
        confirmPassword: (inputValue: string): [boolean, string] => {
            if (inputValue !== password) return [false, "Does not match password"]
            else return [true, ""]
        }
    }

    // This function simply checks that all required fields have a value, it also assembles a tooltip string that can
    // be used to feedback which fields still need to entered to the user.
    const checkFieldsHaveValues = (): [boolean, string] => {
        let tooltip;
        let buttonEnabled = false;
        let missing = [];

        // This gathers a list of the missing required fields so that we can create a tooltip for the disabled button
        if (firstName === "") { missing.push(" First Name") }
        if (lastName === "") { missing.push(" Last Name") }
        if (username === "") { missing.push(" Username") }
        if (email === "") { missing.push(" Email") }
        if (password === "") { missing.push(" Password") }
        if (confirmPassword === "") { missing.push(" Confirm Password") }

        if (missing.length === 0) { tooltip = "" }
        else {
            // We then turn the missing items into a tooltip to be displayed
            tooltip = "Please Add:" + missing.toString();
        }

        // If there are no missing elements, and the password is valid, we can say the form is valid
        if (missing.length === 0) { buttonEnabled = true }

        // Returns a boolean of whether the form is valid and a string for the tooltip
        return [buttonEnabled, tooltip];
    }

    // Makes sure that the inputs are valid, returns a boolean of whether they are, also accepts a boolean
    // of whether or not to update the error states.
    const validateForm = (updateState: boolean): Promise<boolean> =>  {
        // We create a list of all the functions that need to be executed so we can pass it to the Promise
        let functions = [
            validateField.firstName(firstName),
            validateField.lastName(lastName),
            validateField.username(username),
            validateField.email(email),
            validateField.classCode(classCode),
            validateField.password(password),
            validateField.confirmPassword(confirmPassword),
        ]

        // Because some validation functions need to be async as they need to check info with the server, the best way
        // to run all the functions at the same time is to put them in a Promise queue, this means they all execute at
        // the same time, making the execution very quick.
        return Promise.all(functions)
            .then(([firstNameResults, lastNameResults, usernameResults, emailResults, classCodeResults, passwordResults, confirmPasswordResults]) => {
                // Once we have the results, we can check to see if any of them failed, and if so we can set the `valid`
                // variable to false and generate a list of errors to be used as helper-text fields in the text boxes

                let valid = true;
                let newErrors = {...errors};

                let isFieldValid;
                [isFieldValid, newErrors.firstName] = firstNameResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.lastName] = lastNameResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.username] = usernameResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.email] = emailResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.classCode] = classCodeResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.password] = passwordResults;
                if (!isFieldValid) valid = false;

                [isFieldValid, newErrors.confirmPassword] = confirmPasswordResults;
                if (!isFieldValid) valid = false;

                console.log("Validation returned error values:");
                console.log(newErrors);

                // We should only update the state if the `updateState` boolean was true
                if (updateState) setErrors(newErrors);

                // Returns a boolean of whether the form is valid and a string for the tooltip
                return valid;
            });
    }

    // This function will attempt to create a new user by contacting the back end
    const attemptRegister = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();           // Prevents HTML from sending the user to a new page

        setLoading(true)        // Makes the loading wheel appear
        setFail(false)          // Resets the `fail` value in case we've already and failed previously

        // Build up a JavaScript object that can be cast into JSON for the request
        let values = {
            emailAddress: email,
            username: username,
            firstname: firstName,
            lastname: lastName,
            usertype: isTeacher ? "teacher" : "pupil",  // The checkbox only providers a boolean, so we need to convert it
            classcode: classCode,
            newpassword1: password,
            newpassword2: password,
        };

        console.log(`RegisterPage is attempting to create a new user with properties: `);
        console.log(values);

        validateForm(true)
            .then(valid => {
                console.log(`Validation returned '${valid}'`)

                if (valid) {
                    console.log("Attempting to register new user with backend...");

                    let url = PATHS.REGISTER_USER;
                    let data = {formvalues: values};

                    fetch(url, {
                        method: 'POST',                 // or 'PUT'
                        body: JSON.stringify(data),     // data can be `string` or {object}!
                        headers:{
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(res => res.json())        // Turn the response JSON in a JS Object
                        .then(async result =>
                            {
                                if(result.success === true) {
                                    console.log("Registration successful, attempting login");

                                    await handleLogin(username,password)
                                        .then(user => {
                                            console.log("User successfully logged in, attempting to join class")

                                            // Attempt to join the class (if a class code was provided)
                                            if(values.classcode !== ""){
                                                console.log(`Attempting to join class '${values.classcode}'`)

                                                let url = PATHS.JOIN_CLASS;
                                                let data = {class_code: values.classcode};

                                                fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
                                                    .then(res => res.json())
                                                    .then(result => {
                                                        console.log("Backend responded with: " + JSON.stringify(result));
                                                    })
                                                    .catch(error => {
                                                        console.error('Error while trying to join class:', error);
                                                        setFail(true)       // This will make the button go red
                                                    });
                                            }

                                            setSuccess(true);               // This will make the button go green

                                            onRegisterSuccess(isTeacher ? "teacher" : "pupil", true); //justregistered set as true
                                        });
                                history.push('/exercises')
                                }
                                else {
                                    console.log("Registration attempt unsuccessful");
                                    setFail(true);
                                }
                            }
                        )
                        .catch(error => {
                            console.error(`Error while attempting to register new user: ${error}`);
                            setFail(true);
                        });
                } else {
                    setFail(true);
                }
            })
            .catch(error => {
                console.error(error);
                setErrors({...errors, password: "Could not process this request, please try again later"});
                setFail(true);
            })
        setLoading(false);
    }

    // This React Hook will update the errors for the password and confirmPassword fields when the password updates,
    // this allows the user to see in realtime whether the password will be accepted or not.
    useEffect(() => {
        const passwordTimeout = setTimeout(() => {
            console.log("Checking password...")

            let newErrors = {...errors};    // Need to take a slice of the errors so that we get a value not a reference
            let _;

            // We dont want to display an error if the user hasn't filled out the field yet
            if (password !== "")
                [_, newErrors.password] = validateField.password(password);
            else
                newErrors.password = "";

            if (confirmPassword !== "")
                [_, newErrors.confirmPassword] = validateField.confirmPassword(confirmPassword);
            else
                newErrors.confirmPassword = "";

            setErrors(newErrors);
        }, 2000);

        return () => clearTimeout(passwordTimeout);
    }, [password, confirmPassword, isTeacher]);

    // This React Hook sets a function to trigger if nothing has been entered into the email field for a while,
    // this allows the user to see in near-realtime whether the email they entered is valid.
    useEffect(() => {
        const emailTimeout = setTimeout(() => {
            console.log("Looking for email...")

            if (email !== "") {
                validateField.email(email)
                    .then(results => {
                        let newErrors = {...errors};
                        newErrors.email = results[1];
                        setErrors(newErrors);
                    })
            }
            else {
                let newErrors = {...errors};
                newErrors.email = "";
                setErrors(newErrors);
            }
        }, 2000);

        return () => clearTimeout(emailTimeout);
    }, [email]);

    // This React Hook sets a function to trigger if nothing has been entered into the username field for a while,
    // this allows the user to see in near-realtime whether the username they entered is valid.
    useEffect(() => {
        const usernameTimeout = setTimeout(() => {
            console.log("Looking for username...")

            if (username !== "") {
                validateField.username(username)
                    .then(results => {
                        let newErrors = {...errors};
                        newErrors.username = results[1];
                        setErrors(newErrors);
                    })
            }
            else {
                let newErrors = {...errors};
                newErrors.username = "";
                setErrors(newErrors);
            }
        }, 2000);

        return () => clearTimeout(usernameTimeout);
    }, [username]);

    // This React Hook sets a function to trigger if nothing has been entered into the classCode field for a while,
    // this allows the user to see in near-realtime whether the classCode they entered is valid.
    useEffect(() => {
        const classCodeTimeout = setTimeout(() => {
            console.log("Looking for classCode...")

            if (classCode !== "") {
                validateField.classCode(classCode)
                    .then(results => {
                        let newErrors = {...errors};
                        newErrors.classCode = results[1];
                        setErrors(newErrors);
                    })
            }
            else {
                let newErrors = {...errors};
                newErrors.classCode = "";
                setErrors(newErrors);
            }
        }, 2000);

        return () => clearTimeout(classCodeTimeout);
    }, [classCode]);

    let [buttonEnabled, tooltip] = checkFieldsHaveValues();
    return (
        <Container maxWidth="xs">
            <OverrideBackground/>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"           // This uses the HTML auto-complete functionality https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
                                variant="outlined"
                                label="First Name"
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                onChange={onChange(setFirstName)}
                                error={errors.firstName !== ""}
                                helperText={errors.firstName}
                                required fullWidth autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="family-name"
                                variant="outlined"
                                label="Last Name"
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={onChange(setLastName)}
                                error={errors.lastName !== ""}
                                helperText={errors.lastName}
                                required fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Username"
                                id="username"
                                name="username"
                                value={username}
                                onChange={onChange(setUsername)}
                                error={errors.username !== ""}
                                helperText={errors.username}
                                required fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                label="Class Code"
                                value={classCode}
                                onChange={onChange(setClassCode)}
                                error={errors.classCode !== ""}
                                helperText={errors.classCode}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="email"
                                variant="outlined"
                                label="Email Address"
                                id="email"
                                name="email"
                                value={email}
                                onChange={onChange(setEmail)}
                                error={errors.email !== ""}
                                helperText={errors.email}
                                required fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="new-password"
                                variant="outlined"
                                label="Password"
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={onChange(setPassword)}
                                error={errors.password !== ""}
                                helperText={errors.password}
                                required fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="new-password"
                                variant="outlined"
                                label="Confirm Password"
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                value={confirmPassword}
                                onChange={onChange(setConfirmPassword)}
                                error={errors.confirmPassword !== ""}
                                helperText={errors.confirmPassword}
                                required fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox onChange={onCheckboxChange(setIsTeacher)} color="primary" />}
                                label="Teacher Account"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title={tooltip}>
                                <div>
                                    <LoadingButton
                                        text="Create Account"
                                        loading={loading}
                                        valid={buttonEnabled}
                                        success={success}
                                        fail={fail}
                                        onClick={attemptRegister}
                                    />
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Link variant="body2" className={classes.link} component={RouterLink} to={'/login'}>
                                {"Used My Marking Machine before? Then sign in here."}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}

export default withRouter(RegisterPage);
