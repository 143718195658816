import React from 'react';
import { TeacherIDs } from '../../../../my_interfaces';

interface TeachersTableProps {
    teachersIDs: TeacherIDs[];
  }
  
  class TeachersTable extends React.Component<TeachersTableProps> {
    render() {
        const { teachersIDs } = this.props;
    
        const header = [
          <th style={{ textAlign: "left", paddingRight: "0.5em" }} key="firstname">First Name</th>,
          <th style={{ textAlign: "left", padding: "0em 0.5em" }} key="lastname">Last Name</th>,
          <th style={{ textAlign: "left", padding: "0em 0.5em" }} key="username">Username</th>
        ];
    
        const rows = teachersIDs.map((teacher) => (
          <tr key={teacher.U_ID}>
            <td style={{ paddingRight: "0.5em" }}>{teacher.firstname}</td>
            <td style={{ padding: "0em 0.5em" }}>{teacher.lastname}</td>
            <td style={{ padding: "0em 0.5em" }}>{teacher.username}</td>
          </tr>
        ));
    
        return (
          <table>
            <thead>
              <tr>{header}</tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        );
      }
    }
    
export default TeachersTable;
