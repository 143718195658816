import React from 'react';
import update from 'immutability-helper';
import { RouteComponentProps } from 'react-router-dom';
import { DeleteDialog, Dialog } from '../../../../Common';
import { Cohort, PupilIDs, PupilNames, TeacherIDs } from '../../../../my_interfaces';
import {fetchClassPupilData, promoteUserFromClass, removeUserFromClass, resetPassword, unlockUser} from './UserManagerFunctions'
import TeachersTable from './TeachersTable';

export interface APIfetchclassuserdata {
  temppasswords: (string | null | undefined)[], 
  pupilsnames: PupilNames[]
  pupilsIDs: PupilIDs[],
  usersIDs: PupilIDs[],
}

type ClassUserListProps = {
  class_ID: number;
}

interface ClassUserListState {
  removingpupil: { [key: number]: boolean };
  promotingpupil: { [key: number]: boolean };
  showingdialog: boolean;
  showingpromotedialog: boolean;
  selecteduser_U_ID?: number;
  pupilsIDs: PupilIDs[];
  teachersIDs: TeacherIDs[];
  loading: boolean;
}

class ClassUserList extends React.Component<ClassUserListProps, ClassUserListState> {
  constructor(props: ClassUserListProps) {
    super(props);
    this.state = {
      showingdialog: false,
      showingpromotedialog: false,
      removingpupil: {},
      promotingpupil: {},
      selecteduser_U_ID: undefined,
      pupilsIDs: [],
      teachersIDs: [],
      loading: true,
    };
    
    this.passwordReset = this.passwordReset.bind(this);
    this.userUnlock = this.userUnlock.bind(this);
    this.onClickRemoveUser = this.onClickRemoveUser.bind(this);
    this.removeUserConfirmed = this.removeUserConfirmed.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.onClickPromoteUser = this.onClickPromoteUser.bind(this);
    this.promoteUserConfirmed = this.promoteUserConfirmed.bind(this);
    this.promoteUser = this.promoteUser.bind(this);
  }

  async componentDidMount() {
    await this.fetchUserData();
  }

  async fetchUserData() {
    try {
      const [pupilsnames, temppasswords, pupilsIDs, teachersIDs] = await fetchClassPupilData(this.props.class_ID);
  
      const removingpupil: { [key: number]: boolean } = {};
      const promotingpupil: { [key: number]: boolean } = {};
  
      pupilsIDs.forEach(pupilsID => {
        removingpupil[pupilsID.U_ID] = false;
        promotingpupil[pupilsID.U_ID] = false;
      });
  
      this.setState({
        pupilsIDs,
        teachersIDs,
        removingpupil,
        promotingpupil,
        loading: false,
      });
    } catch (error) {
      console.error('Error fetching class user data:', error);
      this.setState({ loading: false });
    }
  }
  

  async passwordReset(U_ID: number) {
    let newpassword = await resetPassword(U_ID, this.props.class_ID);
    if (!newpassword) throw 'new password returned false';

    // Refetch data after resetting the password
    await this.fetchUserData(); 
  }

  async userUnlock(U_ID:number) {
    let success = await unlockUser(U_ID, this.props.class_ID);
    if (!success) throw 'unlock failed';
    
    // Refetch data after unlocking the user
    await this.fetchUserData();
  }

  async removeUser() {
    const U_ID = this.state.selecteduser_U_ID;
    if (U_ID === undefined) throw 'U_ID should not be undefined when removing user';
  
    this.setState(state => ({
      showingdialog: false,
      removingpupil: {
        ...state.removingpupil,
        [U_ID]: true,
      },
    }));
  
    const success = await removeUserFromClass(U_ID, this.props.class_ID);
    if (!success) throw 'remove failed';
    await this.fetchUserData(); // Re-fetch data after user removal
  }
  

  async promoteUser() {
    const U_ID = this.state.selecteduser_U_ID;
    if (U_ID === undefined) throw 'U_ID should not be undefined when promoting user';
  
    this.setState(state => ({
      showingpromotedialog: false,
      promotingpupil: {
        ...state.promotingpupil,
        [U_ID]: true,
      },
    }));
  
    const success = await promoteUserFromClass(U_ID, this.props.class_ID);
    if (!success) throw 'promote failed';
    await this.fetchUserData(); // Re-fetch data after user promotion
  }
  

  onClickRemoveUser(U_ID: number) {
    this.setState({
      showingdialog: true,
      selecteduser_U_ID: U_ID,
    });
  }

  removeUserConfirmed(confirmed: boolean) {
    if (confirmed) {
      this.removeUser();
    } else {
      this.setState({ showingdialog: false });
    }
  }

  onClickPromoteUser(U_ID: number) {
    this.setState({
      showingpromotedialog: true,
      selecteduser_U_ID: U_ID,
    });
  }

  promoteUserConfirmed(option: number) {
    if (option === 1) {
      this.promoteUser();
    } else if (option === 2) {
      this.setState({ showingpromotedialog: false });
    } else {
      throw 'unknown option';
    }
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const pupilsIDs = this.state.pupilsIDs;

    const header = [
      <th style={{textAlign:"left", paddingRight:"0.5em"}} key="firstname">First Name</th>,
      <th style={{textAlign:"left", padding:"0em 0.5em"}} key="lastname">Last Name</th>,
      <th style={{textAlign:"left", padding:"0em 0.5em"}} key="username">Username</th>,
      <th style={{textAlign:"left", padding:"0em 0.5em"}} key="password_reset">Password</th>,
      <th style={{textAlign:"left", padding:"0em 0.5em"}} key="lock">Unlock</th>,
      <th style={{textAlign:"left", padding:"0em 0.5em"}} key="usertype">Type</th>,
      <th style={{textAlign:"left", paddingLeft:"0.5em"}} key="remove">Remove</th>,
    ];

    const sortedpupilsIDs = pupilsIDs.sort((a, b) => {
      if (a.lastname < b.lastname) return -1;
      if (a.lastname > b.lastname) return 1;
      return 0;
    });
    
    const rows = sortedpupilsIDs.map((pupil) => {
      const usertype = pupil.usertype;
      const U_ID = pupil.U_ID;
      const is_class_teacher = this.state.teachersIDs.some(teacher => teacher.U_ID === U_ID);
//
      let promote_button = this.state.promotingpupil[pupil.U_ID]
        ? 'updating...'
        : usertype === 'pupil'
        ? 'pupil'
        : is_class_teacher
        ? 'class teacher'
        : (
          <button onClick={(e) => { e.preventDefault(); this.onClickPromoteUser(pupil.U_ID); }}>
            Add class teacher
          </button>
        );

      return (
        <tr key={pupil.U_ID}>
          <td style={{paddingRight:"0.5em"}}
            >{pupil.firstname}</td>
          <td style={{padding:"0em 0.5em"}}
            >{pupil.lastname}</td>
          <td style={{padding:"0em 0.5em"}}
            >{pupil.username}</td>
          <td style={{padding:"0em 0.5em"}}>
            {(pupil.temppassword === undefined || pupil.temppassword === null) ? (
              <button onClick={(e) => { e.preventDefault(); this.passwordReset(pupil.U_ID); }}>
                Reset
              </button>
            ) : (
              pupil.temppassword
            )}
          </td>
          <td style={{textAlign:"center"}}>
            <button onClick={(e) => { e.preventDefault(); this.userUnlock(pupil.U_ID); }}>
              <i className="fa fa-unlock" />
            </button>
          </td>
          {/* <td>{usertype}</td> */}
          <td>{promote_button}</td>
          <td style={{textAlign:"center"}}>
            {this.state.removingpupil[pupil.U_ID] ? 'removing...' : (
              <button onClick={(e) => { e.preventDefault(); this.onClickRemoveUser(pupil.U_ID); }}>
                ❌
              </button>
            )}
          </td>
        </tr>
      );
    });

    return (
      <div style={{marginTop:"10px"}}>
        <table>
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div hidden={!this.state.showingdialog}>
          <DeleteDialog onDeleteDialogResponse={this.removeUserConfirmed} record_type="pupil" />
        </div>
        <div hidden={!this.state.showingpromotedialog}>
          <Dialog
            onDialogResponse={this.promoteUserConfirmed}
            dialog_text="Are you sure you want to promote this pupil to a teacher of this class?"
            buttons_text={['Yes', 'No']}
          />
        </div>
        <h3 style={{marginTop:"1em"}} >Teachers of the class</h3>
        <TeachersTable
          teachersIDs = {this.state.teachersIDs}
          />
      </div>
    );
  }
}

export default ClassUserList;
