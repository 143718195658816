import React from "react";
import { Cohort } from "../../../../my_interfaces";

interface ClassPageHeaderProps {
	classtodisplay: Cohort
}
export class ClassPageHeader extends React.Component<ClassPageHeaderProps>{

	render(){
		let classtodisplay = this.props.classtodisplay;
		return(
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: '1em'}}>
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<h2>{classtodisplay.name}</h2>
				</div>
				
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', flexGrow: 1 }}>
					<span style={{ fontSize: '1rem', color: 'grey' }}>
						{getPupilNumber(classtodisplay)} students
					</span>
				</div>
				
				<div style={{ display: 'flex', alignItems: 'baseline' }}>
					<span 
						style={{ cursor: 'pointer', fontSize: '30px' }} 
						onClick={() => navigator.clipboard.writeText(classtodisplay.class_code)}
					>
						Class code: {classtodisplay.class_code}
					</span>
				</div>
			</div>

		)
	}
}
export function getPupilNumber(c:Cohort):number{
	let pupilnumber = 0;
	for(let p =0; p<c.Pupils.length; p++){
		let isteacher:boolean = c.Teacher_IDs.indexOf(c.Pupils[p].U_ID)>-1;
		if(!isteacher){pupilnumber++}
	}
	return pupilnumber;
}