import React, { useState, useEffect } from 'react';
import { Worksheet, Question } from '../../my_interfaces'; // Assuming interfaces are in a separate file
import {QuestionpagebyID,  getworksheet } from '../../Common'; // Assuming this is your component to display each question

interface WorksheetViewerProps {
  worksheet_ID: number;
}

const WorksheetViewer: React.FC<WorksheetViewerProps> = ({ worksheet_ID }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [worksheet, setWorksheet] = useState<Worksheet | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async () => {
    try {
      const [fetchedQuestions, fetchedWorksheet] = await getworksheet(worksheet_ID);
      setQuestions(fetchedQuestions);
      setWorksheet(fetchedWorksheet);
    } catch (error) {
      console.error('Error fetching worksheet data:', error);
    }
  };

  const openModal = async () => {
    setIsModalOpen(true);
    await fetchData(); // Fetch data when modal is opened
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button className="button innavigationbar" onClick={openModal}>Quick View</button>
      
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{worksheet?.name}</h2>
            <button onClick={closeModal}>Close</button>
          </div>
            <div className="questions-container">
              {questions.map((question) => (
                <QuestionpagebyID
                  key={question.Q_ID}
                  Q_ID={question.Q_ID}
                  action="view"
                  isdisplayed={true}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorksheetViewer;
