import React from 'react';
import AssignmentRow from './AssignmentRow';
import { Assignment, Worksheet } from '../../../../my_interfaces';
import './AssignmentList.css';

interface AssignmentsTableProps {
    assignments: Assignment[];
    publicworksheets: Worksheet[];
    classID: number;
    onAssignmentButtonClick: (classID: number, assignmentID: number) => void;
    onToggleLive: (classID: number, assignmentID: number, islive: boolean) => void;
}

const AssignmentsList: React.FC<AssignmentsTableProps> = ({
    assignments,
    publicworksheets,
    classID,
    onAssignmentButtonClick,
    onToggleLive,
}) => {
    return (
        <div>
            {/* Header Row */}
            <div className="assignments-header">
                <div className="header-title">Markbook for each assignment (recent first)</div>
                <div className="header-toggle">Hide/Show</div>
            </div>

            {/* Assignment Rows */}
            {assignments.slice()  // Create a shallow copy of the array to avoid mutating the original one
                .reverse()  // Reverse the order of the array
                .map((assignment, index) => {
                const worksheet = publicworksheets.find((ws) => ws.ID === assignment.W_ID);
                if (!worksheet) throw new Error("Worksheet not found for assignment");

                const islive = assignment.islive !== undefined ? assignment.islive : worksheet.type !== "assessment";

                return (
                    <AssignmentRow
                        key={assignment.Assignment_ID}
                        assignment={assignment}
                        worksheet={worksheet}
                        islive={islive}
                        onAssignmentButtonClick={() => onAssignmentButtonClick(classID, assignment.Assignment_ID)}
                        onToggleLive={(newLiveState) =>
                            onToggleLive(classID, assignment.Assignment_ID, newLiveState)
                        }
                    />
                );
            })}
        </div>
    );
};

export default AssignmentsList;
