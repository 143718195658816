import React from 'react';
import { Card, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as COLORS from "@mui/material/colors";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import ClearIcon from '@mui/icons-material/Clear';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {QuestionProps} from "./_LandingPageInterfaces";

// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    faqContainer: {
        marginTop: theme.spacing(8),
    },
    faqTitle: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    faqQuestionContainer: {
        padding: theme.spacing(2),
        height: `calc(100% - ${theme.spacing(1)})`,
    },
    faqQuestionIcon: {
        marginBottom: theme.spacing(1),
        margin: "auto",
        display: "block",
        width: "50px",
        height: "50px",
    },
    faqQuestionIcon_Money: {
        color: COLORS.green[800]
    },
    faqQuestionIcon_Works: {
        color: COLORS.grey[800]
    },
    faqQuestionIcon_Errors: {
        color: COLORS.blue[800]
    },
    faqQuestionTitle: {
        textAlign: "center",
    },
    faqQuestionBody: {
        textAlign: "center"
    }
}));


function Question({title, body, icon}: QuestionProps) {
    const classes = useStyles();

    return (
        <Card elevation={3} className={classes.faqQuestionContainer}>
            {icon}
            <Typography variant="h6" className={classes.faqQuestionTitle}>
                {title}
            </Typography>
            <Typography variant="body2" className={classes.faqQuestionBody}>
                {body}
            </Typography>
        </Card>
    )
}


function FaqSection() {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    return (
        <div className={classes.faqContainer}>
            <Typography variant="h3" className={classes.faqTitle}>
                FAQ
            </Typography>
            <Grid container spacing={3}>
                {/* FAQs REF_17038 */}
                <Grid item xs={12} sm={6} md={4}>
                    <Question
                        title="What's the impact?"
                        body="Our pilot A-level class using the tool extensively improved by 3 grades each in one year. A formal A-B trial of GCSE students showed a grade's worth of improvement."
                        icon={
                            <TrendingUpIcon className={`${classes.faqQuestionIcon} ${classes.faqQuestionIcon_Money}`}  fontSize="large"/>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Question
                        title="How does it work?"
                        body="Experts write a mark scheme and feedback for common errors. An AI uses this mark scheme to mark your answer."
                        icon={
                            <SettingsIcon className={`${classes.faqQuestionIcon} ${classes.faqQuestionIcon_Works}`}  fontSize="large"/>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Question
                        title="What topics are covered?"
                        body="Approximately 50 % of A-level physics and 30 % of GCSE physics, tailored to the AQA specification. Get an up-to-date list by logging in for free."
                        icon={
                            <MenuBookIcon className={`${classes.faqQuestionIcon} ${classes.faqQuestionIcon_Errors}`}  fontSize="large"/>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {/* You can add a new Question here */}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {/* You can add a new Question here */}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {/* You can add a new Question here */}
                </Grid>
            </Grid>
        </div>
    )
}

export default FaqSection;
